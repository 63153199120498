<template>
    <div class="chart-container">
        <div :id="'chart_line_'+dataBlock.id" class="radarChart" style="margin: 0 auto;" :style="'max-width:'+ (chartConfig.width + chartConfig.margin) +'px;'"></div>
    </div>
</template>

<script>
    import * as d3 from 'd3';
    export default {
        name: "GhChartLine",
        props: ['dataBlock', 'chartConfig', 'chartData'],
        data(){
            return {
                refreshData: 0
            }
        },
        mounted() {
            this.chartLine(this.chartData);
        },
        watch: {
            'refreshData'(){
                document.getElementById('chart_line_' + this.dataBlock.id).children[0].remove();
                this.chartLine(this.chartData);
            }
        },
        methods: {
            chartLine(data){
                var checkPositionLegend = ['top','bottom','left','right'];
                var config = {
                    width: this.chartConfig !== undefined && this.chartConfig.width ? this.chartConfig.width : 900,
                    height: this.chartConfig !== undefined && this.chartConfig.height ? this.chartConfig.height : 300,
                    margin: this.chartConfig !== undefined && this.chartConfig.margin ? this.chartConfig.margin : 50,
                    duration: this.chartConfig !== undefined && this.chartConfig.duration ? this.chartConfig.duration : 250,
                    lineOpacity: this.chartConfig !== undefined && this.chartConfig.lineOpacity ? this.chartConfig.lineOpacity : '0.25',
                    lineOpacityHover: this.chartConfig !== undefined && this.chartConfig.lineOpacityHover ? this.chartConfig.lineOpacityHover : '0.85',
                    otherLinesOpacityHover: this.chartConfig !== undefined && this.chartConfig.otherLinesOpacityHover ? this.chartConfig.otherLinesOpacityHover : '0.1',
                    lineStroke: this.chartConfig !== undefined && this.chartConfig.lineStroke ? this.chartConfig.lineStroke : 4,
                    lineStrokeHover: this.chartConfig !== undefined && this.chartConfig.lineStrokeHover ? this.chartConfig.lineStrokeHover : 4,
                    circleOpacity: this.chartConfig !== undefined && this.chartConfig.circleOpacity ? this.chartConfig.circleOpacity : '0.85',
                    circleOpacityOnLineHover: this.chartConfig !== undefined && this.chartConfig.circleOpacityOnLineHover ? this.chartConfig.circleOpacityOnLineHover : '0.25',
                    circleRadius: this.chartConfig !== undefined && this.chartConfig.circleRadius ? this.chartConfig.circleRadius : 4,
                    circleRadiusHover: this.chartConfig !== undefined && this.chartConfig.circleRadiusHover ? this.chartConfig.circleRadiusHover : 6,
                    parseDate: d3.timeParse(this.chartConfig !== undefined && this.chartConfig.parseDate ? this.chartConfig.parseDate : '%d/%m/%Y'),
                    timeFormat: this.chartConfig !== undefined && this.chartConfig.timeFormat ? this.chartConfig.timeFormat : '%b/%Y',
                    lineType: this.chartConfig !== undefined && this.chartConfig.lineType ? this.chartConfig.lineType : 'curveLinear',
                    color: this.chartConfig !== undefined && this.chartConfig.color ? d3.scaleOrdinal().range(this.chartConfig.color) : d3.scaleOrdinal(d3.schemeCategory10),
                    blur: this.chartConfig !== undefined && this.chartConfig.blur ? this.chartConfig.blur : '1.5',
                    yMarkLine: this.chartConfig !== undefined && this.chartConfig.yMarkLine !== undefined ? this.chartConfig.yMarkLine : true,
                    yMarkLineColor: this.chartConfig !== undefined && this.chartConfig.yMarkLineColor ? this.chartConfig.yMarkLineColor : '#eee',
                    yTicks: this.chartConfig !== undefined && this.chartConfig.yTicks ? this.chartConfig.yTicks : 5,
                    yTickSizeOuter: this.chartConfig !== undefined && this.chartConfig.yTickSizeOuter ? this.chartConfig.yTickSizeOuter : 6,
                    yTickPadding: this.chartConfig !== undefined && this.chartConfig.yTickPadding ? this.chartConfig.yTickPadding : 10,
                    xTicks: this.chartConfig !== undefined && this.chartConfig.xTicks ? this.chartConfig.xTicks : 0,
                    xTransform: this.chartConfig !== undefined && this.chartConfig.xTransform ? this.chartConfig.xTransform : '',
                    tooltipGroup: this.chartConfig !== undefined && this.chartConfig.tooltipGroup !== undefined ? this.chartConfig.tooltipGroup : true,
                    tooltipLine: this.chartConfig !== undefined && this.chartConfig.tooltipLine !== undefined ? this.chartConfig.tooltipLine : true,
                    legend: this.chartConfig !== undefined && this.chartConfig.legend !== undefined ? this.chartConfig.legend : true,
                    legendOrientation: this.chartConfig !== undefined && checkPositionLegend.includes(this.chartConfig.legendOrientation) ? this.chartConfig.legendOrientation : 'bottom',
                    xLeftLegendOrientationText: this.chartConfig !== undefined && this.chartConfig.xLeftLegendOrientationText && this.chartConfig.xLeftLegendOrientationText != '' ? this.chartConfig.xLeftLegendOrientationText : -50,
                    xRightLegendOrientationText: this.chartConfig !== undefined && this.chartConfig.xRightLegendOrientationText && this.chartConfig.xRightLegendOrientationText != '' ? this.chartConfig.xRightLegendOrientationText : 50,
                    xLeftLegendOrientationLine: this.chartConfig !== undefined && this.chartConfig.xLeftLegendOrientationLine && this.chartConfig.xLeftLegendOrientationLine != '' ? this.chartConfig.xLeftLegendOrientationLine : -45,
                    xRightLegendOrientationLine: this.chartConfig !== undefined && this.chartConfig.xRightLegendOrientationLine && this.chartConfig.xRightLegendOrientationLine != '' ? this.chartConfig.xRightLegendOrientationLine : 65,
                    yTopLegendOrientationText: this.chartConfig !== undefined && this.chartConfig.yTopLegendOrientationText && this.chartConfig.yTopLegendOrientationText != '' ? this.chartConfig.yTopLegendOrientationText : -25,
                    yLeftRightLegendOrientationText: this.chartConfig !== undefined && this.chartConfig.yLeftRightLegendOrientationText && this.chartConfig.yLeftRightLegendOrientationText != ''? this.chartConfig.yLeftRightLegendOrientationText : 20,
                    yTopLegendOrientationLine: this.chartConfig !== undefined && this.chartConfig.yTopLegendOrientationLine && this.chartConfig.yTopLegendOrientationLine != '' ? this.chartConfig.yTopLegendOrientationLine : -31,
                    yLeftRightLegendOrientationLine: this.chartConfig !== undefined && this.chartConfig.yLeftRightLegendOrientationLine && this.chartConfig.yLeftRightLegendOrientationLine != '' ? this.chartConfig.yLeftRightLegendOrientationLine : 20,
                    widthLegendOrientationLine: this.chartConfig !== undefined && this.chartConfig.widthLegendOrientationLine && this.chartConfig.widthLegendOrientationLine != '' ? this.chartConfig.widthLegendOrientationLine : 12,
                    heightLegendOrientationLine: this.chartConfig !== undefined && this.chartConfig.heightLegendOrientationLine && this.chartConfig.heightLegendOrientationLine != '' ? this.chartConfig.heightLegendOrientationLine : 3,
                    opacityHiddenLegendOrientation: this.chartConfig !== undefined && this.chartConfig.opacityHiddenLegendOrientation && this.chartConfig.opacityHiddenLegendOrientation != '' ? this.chartConfig.opacityHiddenLegendOrientation : '0.3',
                    enableClickLegend: this.chartConfig !== undefined && this.chartConfig.enableClickLegend !== undefined ? this.chartConfig.enableClickLegend : true,
                    language: this.chartConfig !== undefined && this.chartConfig.language !== undefined ? this.chartConfig.language : '',
                    maxValue: this.chartConfig !== undefined && this.chartConfig.maxValue ? this.chartConfig.maxValue : '',
                }

                var height = config.margin;
                if(config.margin > 50 && (config.legendOrientation === undefined || config.legendOrientation == 'left' || config.legendOrientation == 'right')){
                    height = 50;
                }

                var extraData = new Array();
                var data2 = data;
                data2.forEach(function(d) {
                    if(extraData.length == 0) {
                        extraData = d;
                    }else{
                        if(d3.max(d.values, i => parseInt(i.value) > d3.max(extraData.values, d=> d.value))){
                            extraData = d;
                        }
                    }

                    d.values.forEach(function(d) {
                        d.label = config.parseDate(d.label);
                        d.value = +d.value;
                    });
                });

                var xScale = d3.scaleTime()
                    .domain(d3.extent(extraData.values, d => d.label))
                    .range([0, config.width - config.margin]);

                var yScale = d3.scaleLinear()
                    .domain([0, config.maxValue ? config.maxValue :d3.max(extraData.values, d => d.value)])
                    .range([config.height - height, 0]);

                var svg = d3.select("#chart_line_"+this.dataBlock.id).append("svg")
                    .attr("width", (config.width + config.margin)+"px")
                    .attr("height", (config.height + height)+"px")
                    .append('g')
                    .attr("transform", `translate(${config.margin}, ${height})`);

                var filter = svg.append('defs').append('filter').attr('id','glow');
                filter.append('feGaussianBlur').attr('stdDeviation', config.blur).attr('result','coloredBlur');
                var feMerge = filter.append('feMerge');
                feMerge.append('feMergeNode').attr('in','coloredBlur');
                feMerge.append('feMergeNode').attr('in','SourceGraphic');

                if(config.language != '') {
                    d3.timeFormatDefaultLocale(config.language);
                }

                var formatToDateAndTime = d3.timeFormat(config.timeFormat);
                var xAxis = d3.axisBottom(xScale);

                if(config.xTicks != 0) {
                    xAxis.ticks(config.xTicks);
                }else{
                    xAxis.tickFormat(function(d, i) {
                        return formatToDateAndTime(data2[0].values[i].label)
                    });
                }

                var yAxis = config.yMarkLine ? d3.axisLeft(yScale).ticks(config.yTicks).tickSizeInner(-(config.width - config.margin)).tickSizeOuter(config.yTickSizeOuter).tickPadding(config.yTickPadding) : d3.axisLeft(yScale).ticks(config.yTicks);

                if(config.tooltipGroup) {
                    var yFocus = d3.scaleLinear().range([config.height, 0]);
                    var xFocus = d3.scaleUtc(d3.extent(data2.map(d => d.label)), [0, config.width]);
                }

                svg.append('g')
                    .attr('class', 'x axis legendX')
                    .attr('transform', 'translate(0,' + (config.height - height) + ')')
                    .call(xAxis)
                    .selectAll("text")
                    .attr('transform', config.xTransform);

                svg.append("g")
                    .attr('id', 'id_extra_'+this.dataBlock.id)
                    .attr("class", "y axis legendY")
                    .call(yAxis)
                    .append('text')
                    .attr("y", 15)
                    .attr('transform', 'rotate(-90)')
                    .attr('fill', '#000');

                d3.select('#id_extra_'+this.dataBlock.id).selectAll('line')
                    .style('fill', 'none')
                    .style('stroke', config.yMarkLineColor)
                    .style('visibility', function(d, i) {
                        if (i == 0){
                            return 'hidden'
                        }
                    })
                    .style('shape-rendering','crispEdges');

                var line = d3.line()
                    .x(d => xScale(d.label))
                    .y(d => yScale(d.value)).curve(d3[config.lineType]);

                let lines = svg.append('g')
                    .attr('class', 'lines');

                var id = this.dataBlock.id;
                lines.selectAll('.line-group_'+id)
                    .data(data2).enter()
                    .append('g')
                    .attr('class', 'line-group_'+id)
                    .attr('id', function(d, i){return 'line_group_'+id+i})
                    .style('stroke-width', config.lineStroke+'px')
                    .on("mouseover", function(d, i) {
                        if(config.tooltipLine) {
                            var length = i.values.length;
                            var count = 0;
                            i.values.forEach((items) => {
                                var nearestDateXCord = xScale(items.label);
                                var nearestDateYCord = yScale(items.value);

                                nearestDateXCord = count == 0 ? nearestDateXCord + 15 : count == length - 1 ? nearestDateXCord - 10 : nearestDateXCord

                                svg.append("text")
                                    .attr("class", "text title-text_" + id)
                                    .style("fill", 'black')
                                    .text(items.value)
                                    .attr("text-anchor", "middle")
                                    .attr("x", nearestDateXCord)
                                    .attr("y", nearestDateYCord - 15);

                                count++;
                            })
                        }
                    })
                    .on("mouseout", function() {
                        if(config.tooltipLine) {
                            svg.selectAll(".title-text_" + id).remove();
                        }
                    })
                    .append('path')
                    .attr('class', 'line_' + id)
                    .attr('d', d => line(d.values))
                    .attr("fill", "none")
                    .style('filter' , 'url(#glow)')
                    .style('stroke', (d, i) => config.color(i))
                    .style('opacity', config.lineOpacity)
                    .style('stroke-width', config.lineStroke + 'px')
                    .style('stroke-dasharray', function(d){return d.dash ? '10 3' : ''})
                    .on("mouseover", function() {
                        d3.selectAll('.line_' + id)
                            .style('opacity', config.otherLinesOpacityHover);
                        d3.selectAll('.circle_' + id)
                            .style('opacity', config.circleOpacityOnLineHover);
                        d3.select(this)
                            .style('opacity', config.lineOpacityHover)
                            .style("stroke-width", config.lineStrokeHover + 'px')
                            .style("cursor", "pointer");
                    })
                    .on("mouseout", function() {
                        d3.selectAll(".line_" + id)
                            .style('opacity', config.lineOpacity);
                        d3.selectAll('.circle_' + id)
                            .style('opacity', config.circleOpacity);
                        d3.select(this)
                            .style("stroke-width", config.lineStroke + 'px')
                            .style("cursor", "none");
                    });

                if(config.tooltipGroup) {
                    var groupValuesByX = {};
                    data2.forEach((source) => {
                        source.values.map(record => {
                            if (groupValuesByX[record.label] !== undefined) {
                                groupValuesByX[record.label][source.name] = record.value;
                            } else {
                                groupValuesByX[record.label] = {}
                                groupValuesByX[record.label][source.name] = record.value;
                            }
                        });
                    });

                    var availableDates = Object.keys(groupValuesByX);

                    var mouseLine = lines.selectAll('.line-group_'+id)
                        .append("path")
                        .attr("class", "mouse-line")
                        .attr("stroke", "#cdcdcd")
                        .attr("stroke-width", 2)
                        .attr("opacity", "0");

                    var tooltip = lines
                        .append("g")
                        .attr("class", "tooltip-wrapper")
                        .attr("display", "none");

                    var tooltipBackground = tooltip.append("rect").attr("fill", "#e8e8e8");

                    var tooltipText = tooltip.append("text");
                }

                lines.selectAll("circle-group_"+id)
                    .data(data2).enter()
                    .append("g")
                    .style("fill", (d, i) => config.color(i))
                    .attr('id', function(d, i){return 'circle_group_' + id +i})
                    .selectAll("circle")
                    .data(d => {return d.values}).enter()
                    .append("g")
                    .attr("class", "circle_" + id)
                    .on("mouseover", function(d, i) {
                        if(config.tooltipGroup) {
                            tooltip.attr("display", null);
                            var nearestDateIndex = d3.bisect(availableDates.map(d => new Date(d)), i.label);
                            var d0 = new Date(availableDates[nearestDateIndex - 1]);
                            var d1 = new Date(availableDates[nearestDateIndex]);

                            var closestDate;
                            if (d0 < xFocus.domain()[0]) {
                                closestDate = d1;
                            } else if (d1 > xFocus.domain()[1]) {
                                closestDate = d0;
                            } else {
                                closestDate = i.label - d0 > d1 - i.label ? d1 : d0;
                            }

                            var nearestDateYValues = groupValuesByX[closestDate];
                            var nearestDateXCord = xScale(i.label);
                            // var nearestDateYCord = yScale(i.value);

                            mouseLine.attr("d", `M ${nearestDateXCord} 0 V ${config.height - height}`).attr("opacity", "1");

                            tooltipText.selectAll(".tooltip-text-line").remove();
                            lines.selectAll(".tooltip-line-circles").remove();
                            var formatTime = d3.timeFormat(config.timeFormat);
                            tooltipText
                                .append("tspan")
                                .attr("class", "tooltip-text-line")
                                .attr("x", "5")
                                .attr("y", "5")
                                .attr("dy", "13px")
                                .attr("font-weight", "bold")
                                .text(`${formatTime(closestDate)}`);

                            var arrayIds = document.querySelectorAll('[id^="circle_group_'+ id +'"]');
                            var count = 0;
                            for (let key of Object.keys(nearestDateYValues)) {
                                if (arrayIds[count].style.visibility != 'hidden') {
                                    lines
                                        .append("circle")
                                        .attr("class", "tooltip-line-circles")
                                        .attr("r", 5)
                                        .attr("fill", config.color(count))
                                        .attr("cx", nearestDateXCord)
                                        .attr("cy", yFocus(nearestDateYValues[key]));

                                    tooltipText
                                        .append("tspan")
                                        .attr("class", "tooltip-text-line")
                                        .attr("x", "5")
                                        .attr("dy", `14px`)
                                        .attr("fill", config.color(count))
                                        .text(`${key}: ${key == 'name' ? nearestDateYValues[key] : nearestDateYValues[key].toFixed(2)}`);
                                }
                                count++;
                            }

                            var tooltipWidth = tooltipText.node().getBBox().width;
                            var tooltipHeight = tooltipText.node().getBBox().height;
                            tooltipBackground.attr("width", tooltipWidth + 10).attr("height", tooltipHeight + 10).style('filter', 'url(#glow)');
                            var xCord = nearestDateIndex == Object.keys(groupValuesByX).length ? nearestDateXCord - (tooltipWidth + 40) : nearestDateXCord + 10
                            tooltip.attr("transform", "translate(" + (xCord + 10) + "," + 5 + ")");

                            tooltip.raise();
                        }else {
                            d3.select(this)
                                .style("cursor", "pointer")
                                .append("text")
                                .attr("class", "text")
                                .text(`${i.value}`)
                                .attr('fill', 'black')
                                .attr("x", d => xScale(d.label) + 5)
                                .attr("y", d => yScale(d.value) - 10);
                        }
                    })
                    .on("mouseout", function() {
                        if(config.tooltipGroup) {
                            mouseLine.attr("opacity", "0").attr('d', '');
                            tooltip.attr("display", "none");
                        }else {
                            d3.select(this)
                                .style("cursor", "none")
                                .transition()
                                .duration(config.duration)
                                .selectAll(".text").remove();
                        }
                    })
                    .append("circle")
                    .attr("cx", d => xScale(d.label))
                    .attr("cy", d => yScale(d.value))
                    .attr("r", config.circleRadius)
                    .style('opacity', config.circleOpacity)
                    .on("mouseover", function() {
                        d3.select(this)
                            .transition()
                            .duration(config.duration)
                            .attr("r", config.circleRadiusHover);
                    })
                    .on("mouseout", function() {
                        d3.select(this)
                            .transition()
                            .duration(config.duration)
                            .attr("r", config.circleRadius);
                    });

                if(config.legend) {
                    var legendSpace = config.width / data2.length;

                    var legend = svg.append('g').attr('class', 'legendGroup');
                    data2.forEach(function (d, i) {
                        var legendGroup = legend.append('g').attr('class', 'legend').attr('id', 'legend_' + id + i);
                        legendGroup.selectAll(".lineLegend"+id)
                            .append('g')
                            .attr('class', 'lineLegend'+id)
                            .attr('transform', function (d, i) {
                                return 'translate(' + config.legendOrientation == 'left' ? config.xLeftLegendOrientationText : config.legendOrientation == 'right' ? config.width - config.xRightLegendOrientationText : (legendSpace / 2) + i * legendSpace + ',' + config.legendOrientation == 'top' ? config.yTopLegendOrientationText : config.legendOrientation == 'left' ? i * config.yLeftRightLegendOrientationText : config.height + ')';
                            });

                        legendGroup.append("text")
                            .attr("x", config.legendOrientation == 'left' ? config.xLeftLegendOrientationText : config.legendOrientation == 'right' ? config.width - config.xRightLegendOrientationText : (legendSpace / 2) + i * legendSpace)
                            .attr("y", config.legendOrientation == 'top' ? config.yTopLegendOrientationText : config.legendOrientation == 'left' || config.legendOrientation == 'right' ? (i+0.3) * config.yLeftRightLegendOrientationText : config.height)
                            .attr("class", "legend")
                            .attr('text-anchor', config.legendOrientation == 'left' ? 'end' : '')
                            .style('cursor', 'pointer')
                            .style("fill", config.color(i))
                            .text(d.name);

                        legendGroup.append("rect")
                            .attr("fill", config.color(i))
                            .attr('x', config.legendOrientation == 'left' ? config.xLeftLegendOrientationLine : config.legendOrientation == 'right' ? config.width - config.xRightLegendOrientationLine : ((legendSpace / 2) + i * legendSpace) - 20)
                            .attr('y', config.legendOrientation == 'top' ? config.yTopLegendOrientationLine : config.legendOrientation == 'left' || config.legendOrientation == 'right' ? i * config.yLeftRightLegendOrientationLine : config.height - 6)
                            .attr("width", config.widthLegendOrientationLine)
                            .attr('height', config.heightLegendOrientationLine);

                        if(config.enableClickLegend) {
                            legendGroup.on("click", function () {
                                document.getElementById('line_group_' + id + i).style.visibility = document.getElementById('line_group_' + id + i).style.visibility == 'hidden' ? '' : 'hidden';
                                document.getElementById('circle_group_' + id + i).style.visibility = document.getElementById('circle_group_' + id + i).style.visibility == 'hidden' ? '' : 'hidden';
                                d3.select(this).style('opacity', document.getElementById('line_group_' + id + i).style.visibility == 'hidden' ? config.opacityHiddenLegendOrientation : '');
                            });

                            if (d.show == false) {
                                document.getElementById('legend_' + id + i).dispatchEvent(new Event('click'));
                            }
                        }
                    });
                }
            },
        }
    }
</script>