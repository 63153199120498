<template>
    <div class="chart-container" >
        <div :id="'chart_area_'+dataBlock.id" class="radarChart" style="margin: 0 auto;" :style="'max-width:'+ (chartConfig.width + (chartConfig.margin.left + chartConfig.margin.right)) +'px;'"></div>
    </div>
</template>

<script>
import * as d3 from 'd3';
export default {
    name: "GhChartArea",
    props: ['dataBlock', 'chartConfig', 'chartData'],
    data(){
        return {
            refreshData: 0
        }
    },
    mounted() {
        this.chartArea(this.chartData[1].data, this.chartData[0].labels);
    },
    watch: {
        'refreshData'(){
            document.getElementById('chart_area_' + this.dataBlock.id).children[0].remove();
            this.chartArea(this.chartData[1].data, this.chartData[0].labels);
        }
    },
    methods: {
        chartArea(data, labels){
            var checkPositionLegend = ['top','bottom','left','right'];
            var config = {
                width: this.chartConfig !== undefined && this.chartConfig.width ? this.chartConfig.width : 900,
                height: this.chartConfig !== undefined && this.chartConfig.height ? this.chartConfig.height : 300,
                margin: {
                    top: this.chartConfig !== undefined && this.chartConfig.margin !== undefined && this.chartConfig.margin.top ? this.chartConfig.margin.top : 10,
                    bottom: this.chartConfig !== undefined && this.chartConfig.margin !== undefined && this.chartConfig.margin.bottom ? this.chartConfig.margin.bottom : 50,
                    left: this.chartConfig !== undefined && this.chartConfig.margin !== undefined && this.chartConfig.margin.top ? this.chartConfig.margin.top : 100,
                    right: this.chartConfig !== undefined && this.chartConfig.margin !== undefined && this.chartConfig.margin.right ? this.chartConfig.margin.right : 100
                },
                parseDate: d3.timeParse(this.chartConfig !== undefined && this.chartConfig.parseDate ? this.chartConfig.parseDate : '%d/%m/%Y'),
                timeFormat: this.chartConfig !== undefined && this.chartConfig.timeFormat ? this.chartConfig.timeFormat : '%Y',
                color: this.chartConfig !== undefined && this.chartConfig.color ? d3.scaleOrdinal().range(this.chartConfig.color) : d3.scaleOrdinal(d3.schemeCategory10),
                type: this.chartConfig !== undefined && this.chartConfig.type ? this.chartConfig.type : 'absolute', //relative
                xAxis: {
                    type: this.chartConfig !== undefined && this.chartConfig.xAxis !== undefined && this.chartConfig.xAxis.type ? this.chartConfig.xAxis.type : 'timeYear',
                    intervalTicks: this.chartConfig !== undefined && this.chartConfig.xAxis !== undefined && this.chartConfig.xAxis.intervalTicks ? this.chartConfig.xAxis.intervalTicks : 1,
                    text: {
                        dx: this.chartConfig !== undefined && this.chartConfig.xAxis !== undefined && this.chartConfig.xAxis.text !== undefined && this.chartConfig.xAxis.text.dx ? this.chartConfig.xAxis.text.dx : '',
                        dy: this.chartConfig !== undefined && this.chartConfig.xAxis !== undefined && this.chartConfig.xAxis.text !== undefined && this.chartConfig.xAxis.text.dy ? this.chartConfig.xAxis.text.dy : '1.6em',
                        x: this.chartConfig !== undefined && this.chartConfig.xAxis !== undefined && this.chartConfig.xAxis.text !== undefined && this.chartConfig.xAxis.text.x ? this.chartConfig.xAxis.text.x : '',
                        y: this.chartConfig !== undefined && this.chartConfig.xAxis !== undefined && this.chartConfig.xAxis.text !== undefined && this.chartConfig.xAxis.text.y ? this.chartConfig.xAxis.text.y : '',
                        style: this.chartConfig !== undefined && this.chartConfig.xAxis !== undefined && this.chartConfig.xAxis.text !== undefined && this.chartConfig.xAxis.text.style ? this.chartConfig.xAxis.text.style : '',
                        transform: this.chartConfig !== undefined && this.chartConfig.xAxis !== undefined && this.chartConfig.xAxis.text !== undefined && this.chartConfig.xAxis.text.transform ? this.chartConfig.xAxis.text.transform : '',
                    },
                },
                yAxis: {
                    tickSizeOuter: this.chartConfig !== undefined && this.chartConfig.yAxis !== undefined && this.chartConfig.yAxis.tickSizeOuter ? this.chartConfig.yAxis.tickSizeOuter : 6,
                    tickPadding: this.chartConfig !== undefined && this.chartConfig.yAxis !== undefined && this.chartConfig.yAxis.tickPadding ? this.chartConfig.yAxis.tickPadding : 10,
                    gridStrokeColor: this.chartConfig !== undefined && this.chartConfig.yAxis !== undefined && this.chartConfig.yAxis.gridStrokeColor ? this.chartConfig.yAxis.gridStrokeColor : '#ccc',
                    gridStrokeOpacity: this.chartConfig !== undefined && this.chartConfig.yAxis !== undefined && this.chartConfig.yAxis.gridStrokeOpacity ? this.chartConfig.yAxis.gridStrokeOpacity : '0.2'
                },
                area: {
                    opacityDefault : this.chartConfig !== undefined && this.chartConfig.area !== undefined && this.chartConfig.area.opacityDefault ? this.chartConfig.area.opacityDefault : '0.25',
                    opacityIn : this.chartConfig !== undefined && this.chartConfig.area !== undefined && this.chartConfig.area.opacityIn ? this.chartConfig.area.opacityIn : '0.85',
                    opacityOut : this.chartConfig !== undefined && this.chartConfig.area !== undefined && this.chartConfig.area.opacityOut ? this.chartConfig.area.opacityOut : '0.1'
                },
                tooltip: {
                    colorStroke: this.chartConfig !== undefined && this.chartConfig.tooltip !== undefined && this.chartConfig.tooltip.colorStroke ? this.chartConfig.tooltip.colorStroke : '#cdcdcd',
                    widthStroke: this.chartConfig !== undefined && this.chartConfig.tooltip !== undefined && this.chartConfig.tooltip.widthStroke ? this.chartConfig.tooltip.widthStroke : 2,
                    colorBackground: this.chartConfig !== undefined && this.chartConfig.tooltip !== undefined && this.chartConfig.tooltip.colorBackground ? this.chartConfig.tooltip.colorBackground : '#e8e8e8'
                },
                legendOrientation: this.chartConfig !== undefined && checkPositionLegend.includes(this.chartConfig.legendOrientation) ? this.chartConfig.legendOrientation : 'bottom',
                xLeftLegendOrientationText: this.chartConfig !== undefined && this.chartConfig.xLeftLegendOrientationText && this.chartConfig.xLeftLegendOrientationText != '' ? this.chartConfig.xLeftLegendOrientationText : -50,
                xRightLegendOrientationText: this.chartConfig !== undefined && this.chartConfig.xRightLegendOrientationText && this.chartConfig.xRightLegendOrientationText != '' ? this.chartConfig.xRightLegendOrientationText : 50,
                xLeftLegendOrientationLine: this.chartConfig !== undefined && this.chartConfig.xLeftLegendOrientationLine && this.chartConfig.xLeftLegendOrientationLine != '' ? this.chartConfig.xLeftLegendOrientationLine : -45,
                xRightLegendOrientationLine: this.chartConfig !== undefined && this.chartConfig.xRightLegendOrientationLine && this.chartConfig.xRightLegendOrientationLine != '' ? this.chartConfig.xRightLegendOrientationLine : 65,
                yTopLegendOrientationText: this.chartConfig !== undefined && this.chartConfig.yTopLegendOrientationText && this.chartConfig.yTopLegendOrientationText != '' ? this.chartConfig.yTopLegendOrientationText : -25,
                yLeftRightLegendOrientationText: this.chartConfig !== undefined && this.chartConfig.yLeftRightLegendOrientationText && this.chartConfig.yLeftRightLegendOrientationText != ''? this.chartConfig.yLeftRightLegendOrientationText : 20,
                yTopLegendOrientationLine: this.chartConfig !== undefined && this.chartConfig.yTopLegendOrientationLine && this.chartConfig.yTopLegendOrientationLine != '' ? this.chartConfig.yTopLegendOrientationLine : -31,
                yLeftRightLegendOrientationLine: this.chartConfig !== undefined && this.chartConfig.yLeftRightLegendOrientationLine && this.chartConfig.yLeftRightLegendOrientationLine != '' ? this.chartConfig.yLeftRightLegendOrientationLine : 20,
                widthLegendOrientationLine: this.chartConfig !== undefined && this.chartConfig.widthLegendOrientationLine && this.chartConfig.widthLegendOrientationLine != '' ? this.chartConfig.widthLegendOrientationLine : 12,
                heightLegendOrientationLine: this.chartConfig !== undefined && this.chartConfig.heightLegendOrientationLine && this.chartConfig.heightLegendOrientationLine != '' ? this.chartConfig.heightLegendOrientationLine : 3,
                opacityHiddenLegendOrientation: this.chartConfig !== undefined && this.chartConfig.opacityHiddenLegendOrientation && this.chartConfig.opacityHiddenLegendOrientation != '' ? this.chartConfig.opacityHiddenLegendOrientation : '0.3',
                enableClickLegend: this.chartConfig !== undefined && this.chartConfig.enableClickLegend !== undefined ? this.chartConfig.enableClickLegend : true,
            }

            data.forEach(function(item){
                item.year = config.parseDate(item.year);
            });

            var timeFormat2 = config.timeFormat;
            if(config.timeFormat === '%H:%M'){
                timeFormat2 = '%H:%M:%S';
            }

            var formatTime = d3.timeFormat(config.timeFormat);
            var formatTime2 = d3.timeFormat(timeFormat2);

            var xScale = d3.scaleTime()
                .domain(d3.extent(data, function (d) { return d.year; }))
                .range([0, config.width - (config.margin.left)]);

            var yScale = d3.scaleLinear().range([config.height - (config.margin.top + config.margin.bottom), 0]);

            var svg = d3.select('#chart_area_' + this.dataBlock.id).append('svg')
                .attr('width', config.width + (config.margin.left + config.margin.right))
                .attr('height', config.height + (config.margin.top + config.margin.bottom))
                .append('g')
                .attr('transform', 'translate(' + (config.margin.left + config.margin.right) + ',' + (config.margin.top + config.margin.bottom) + ')');

            var keys = [];
            keys = labels;

            var keys_stack = [];
            labels.forEach(function(items, index){
                if(items.show) {
                    keys_stack[index] = items.key;
                }
            });

            if(config.type != 'absolute'){
                var keys_copy = [];
                var grouped = d3.groups(data, d => d.year);
                var count = 0;
                grouped.forEach(function(g){
                    var yearSum = d3.sum(g[1], function(d){
                        var suma = 0;

                        keys_stack.forEach(function(i){
                            if(d[i] !== undefined) {
                                suma += d[i];
                            }
                        });

                        return suma;
                    });

                    g[1].forEach((d) => {
                        keys_stack.forEach(function(i, index){
                            if(i+'_percentage' !== '_percentage') {
                                data[count][i + '_percentage'] = d[i] / yearSum * 100;
                                if (!keys_copy.includes(i + '_percentage')) {
                                    keys_copy[index] = i + '_percentage';
                                }
                            }
                        });
                    });
                    count++;
                });

                keys_stack = keys_copy;
            }

            var xAxis = d3.axisBottom(xScale);

            if(config.xAxis.type == 'timeYear'){
                xAxis.ticks(d3.timeYear.every(config.xAxis.intervalTicks));
            }else if(config.xAxis.type == 'timeMonth'){
                xAxis.ticks(d3.timeMonth.every(config.xAxis.intervalTicks));
            }else if(config.xAxis.type == 'timeDay'){
                xAxis.ticks(d3.timeDay.every(config.xAxis.intervalTicks));
            }else if(config.xAxis.type == 'timeHour'){
                xAxis.ticks(d3.timeHour.every(config.xAxis.intervalTicks));
            }else{
                xAxis.tickValues(data.map(d=>d.year));
            }

            xAxis.tickFormat(formatTime);

            svg.append('g')
                .attr('transform', 'translate(0, ' + (config.height - (config.margin.top + config.margin.bottom)) + ')')
                .call(xAxis)
                .selectAll("text")
                .attr('style', config.xAxis.text.style)
                .attr('transform', config.xAxis.text.transform)
                .attr('x', config.xAxis.text.x)
                .attr('y', config.xAxis.text.y)
                .attr('dx', config.xAxis.text.dx)
                .attr('dy', config.xAxis.text.dy);

            var stack = d3.stack().keys(keys_stack);
            var stackedData = stack(data);

            if(stackedData.length != 0) {
                yScale.domain([0, d3.max(stackedData[stackedData.length - 1], function (d) {
                    return d[1]
                })]);

                var yAxis = d3.axisLeft(yScale);
                var yFormat = config.type != 'absolute' ? d3.format('.0%') : d3.format('~s');
                if (config.type != 'absolute') {
                    yAxis.tickFormat(function (d) {
                        return yFormat(d / 100)
                    });
                } else {
                    yAxis.tickFormat(function (d) {
                        return yFormat(d)
                    });
                }

                yAxis.tickSizeInner(-(config.width - config.margin.left)).tickSizeOuter(config.yAxis.tickSizeOuter).tickPadding(config.yAxis.tickPadding);

                svg.append('g')
                    .attr('id', 'id_area_mark_' + this.dataBlock.id)
                    .attr("class", "y axis legendY")
                    .call(yAxis);

                d3.select('#id_area_mark_' + this.dataBlock.id).selectAll('line')
                    .style('fill', 'none')
                    .style('stroke', config.yAxis.gridStrokeColor)
                    .style('stroke-opacity', config.yAxis.gridStrokeOpacity)
                    .style('visibility', function (d, i) {
                        if (i == 0) {
                            return 'hidden'
                        }
                    })
                    .style('shape-rendering', 'crispEdges');

                var area = d3.area()
                    .x(function (d) {
                        return xScale(d.data.year);
                    })
                    .y0(function (d) {
                        return yScale(d[0]);
                    })
                    .y1(function (d) {
                        return yScale(d[1]);
                    })

                var line = d3.line()
                    .x(d => xScale(d.data.year))
                    .y(d => yScale(d[1]));


                var parent_series = svg.append('g')
                    .attr('class', 'parent_series_' + this.dataBlock.id);

                parent_series.append('rect')
                    .attr('width', config.width - config.margin.left)
                    .attr('height', config.height - (config.margin.top + config.margin.bottom))
                    .attr('fill', 'transparent');

                var series = parent_series.selectAll('g.series_' + this.dataBlock.id)
                    .data(stackedData)
                    .enter()
                    .append('g')
                    .attr('class', 'series_' + this.dataBlock.id);

                var id = this.dataBlock.id;

                series.append('path')
                    .attr('class', 'line')
                    .attr('id', function (d, i) {
                        return 'line_area_' + id + '_' + i
                    })
                    .attr('d', function (d) {
                        return line(d).indexOf('NaN') != '-1' ? '' : line(d);
                    })
                    .attr("fill", "none")
                    .style('stroke', function (d, i) {
                        return config.color(i)
                    })
                    .style('filter', 'url(#glow)')

                series.append('path')
                    .attr('class', 'area_' + id)
                    .attr('id', function (d, i) {
                        return 'area_area_' + id + '_' + i
                    })
                    .style('fill', function (d, i) {
                        return config.color(i)
                    })
                    .style('fill-opacity', config.area.opacityDefault)
                    .attr('d', function (d) {
                        return area(d).indexOf('NaN') != '-1' ? '' : area(d);
                    })
                    .on("mouseover", function () {
                        d3.selectAll('.area_' + id)
                            .style('fill-opacity', config.area.opacityOut);
                        d3.select(this)
                            .style('fill-opacity', config.area.opacityIn)
                            .style('filter', 'url(#glow)');
                    })
                    .on("mouseout", function () {
                        d3.selectAll('.area_' + id)
                            .style('fill-opacity', config.area.opacityDefault)
                            .style('filter', '');
                    });

                let lines = svg.append('g')
                    .attr('class', 'lines')
                    .style('pointer-events', 'none');

                var mouseLine = lines
                    .append("path")
                    .attr("class", "mouse-line")
                    .attr("stroke", config.tooltip.colorStroke)
                    .attr("stroke-width", config.tooltip.widthStroke)
                    .attr("opacity", "0");

                var tooltip = lines
                    .append("g")
                    .attr("class", "tooltip-wrapper")
                    .attr("display", "none");

                var tooltipBackground = tooltip.append("rect").attr("fill", config.tooltip.colorBackground);

                var tooltipText = tooltip.append("text");
                d3.selectAll('.parent_series_' + this.dataBlock.id).on('mousemove', function (d) {
                    tooltip.attr("display", null);
                    var x = xScale.invert(d3.pointer(d)[0]);
                    var tooltipData = {}
                    var nearestDateIndex = 0;
                    var cordX;
                    if (config.timeFormat != '%b/%Y' && config.timeFormat != '%m/%Y') {
                        if(config.timeFormat == '%H:%M'){
                            cordX = Math.round(formatTime2(x).split(':')[2]) > 30 ? Math.round(formatTime2(x).split(':')[1]) : Math.round(formatTime2(x).split(':')[1]) - 1;
                        }else {
                            cordX = Math.round(formatTime(x));
                        }

                        if (config.timeFormat == '%d') {
                            var hour = d3.timeFormat('%p');
                            var value_hour = hour(x);

                            data.forEach(function (item, key) {
                                var item_year = value_hour !== undefined && value_hour == 'PM' ? Math.round(formatTime(item.year)) - 1 : Math.round(formatTime(item.year));
                                if (item_year == cordX) {
                                    tooltipData = item;
                                    nearestDateIndex = key;
                                }
                            });
                        }else if(config.timeFormat == '%H:%M') {
                            var hour2 = d3.timeFormat('%H:%M');
                            var value_hour2 = hour2(x);
                            data.forEach(function (item, key) {
                                var item_year = value_hour2 !== undefined ? Math.round(formatTime2(item.year).split(':')[1]) - 1 : Math.round(formatTime2(item.year).split(':')[1]);
                                if (item_year == cordX) {
                                    tooltipData = item;
                                    nearestDateIndex = key;
                                }
                            })
                        } else {
                            var month = d3.timeFormat('%m');
                            var value_month = month(x);

                            data.forEach(function (item, key) {
                                var item_year = value_month !== undefined && (value_month == '07' || value_month == '08' || value_month == '09' || value_month == '10' || value_month == '11' || value_month == '12') ? Math.round(formatTime(item.year)) - 1 : Math.round(formatTime(item.year));
                                if (item_year == cordX) {
                                    tooltipData = item;
                                    nearestDateIndex = key;
                                }
                            });
                        }
                    } else if (config.timeFormat == '%b/%Y' || config.timeFormat == '%m/%Y') {
                        var formatDay = d3.timeFormat('%d');
                        var formatMonth = d3.timeFormat('%m');
                        var formatYear = d3.timeFormat('%Y');
                        var formatMonthYear = d3.timeFormat('%m/%Y');
                        cordX = Math.round(formatDay(x));
                        var realMonth = formatMonth(x);
                        var realYear = formatYear(x);

                        var countMounth = 0;
                        var countYear = 0;
                        data.forEach(function (item, key) {
                            if (cordX !== undefined && cordX > 15) {
                                if (parseInt(realMonth) == 12 && countYear == 0 && countMounth == 0) {
                                    realYear = parseInt(realYear) + 1;
                                    realMonth = 0;
                                    countYear++;
                                } else if (parseInt(realMonth) <= 11 && countMounth == 0) {
                                    realMonth = parseInt(realMonth) + 1;
                                    countMounth++;
                                }
                            }

                            realMonth = realMonth.toString().length == 1 ? '0' + realMonth : realMonth;
                            var realDate = realMonth + '/' + realYear;
                            if (formatMonthYear(item.year) == realDate) {
                                tooltipData = item;
                                nearestDateIndex = key;
                            }
                        });
                    }


                    var nearestDateXCord = xScale(tooltipData.year);
                    if(nearestDateXCord !== undefined) {
                        mouseLine.attr("d", `M ${nearestDateXCord} 0 V ${config.height - (config.margin.top + config.margin.bottom)}`).attr("opacity", "0.8");

                        tooltipText.selectAll(".tooltip-text-line").remove();
                        lines.selectAll(".tooltip-line-circles").remove();
                        tooltipText
                            .append("tspan")
                            .attr("class", "tooltip-text-line")
                            .attr("x", "5")
                            .attr("y", "5")
                            .attr("dy", "13px")
                            .attr("font-weight", "bold")
                            .text(`${formatTime(tooltipData.year)}`);

                        var count = 0;
                        var enter = false;
                        keys.forEach(function (items) {
                            if (items.show && tooltipData[items.key] !== undefined) {
                                enter = true;
                                tooltipText
                                    .append("tspan")
                                    .attr("class", "tooltip-text-line")
                                    .attr("x", "5")
                                    .attr("dy", `14px`)
                                    .attr("fill", config.color(count))
                                    .text(`${items.text}: ${tooltipData[items.key].toFixed(2)}`);
                            }

                            count++;
                        });

                        if (enter) {
                            var tooltipWidth = tooltipText.node().getBBox().width;
                            var tooltipHeight = tooltipText.node().getBBox().height;
                            tooltipBackground.attr("width", tooltipWidth + 10).attr("height", tooltipHeight + 10).style('filter', 'url(#glow)');
                            var xCord = nearestDateIndex == (Object.keys(data).length - 1) ? nearestDateXCord - (tooltipWidth + 20) : nearestDateXCord + 10
                            tooltip.attr("transform", "translate(" + xCord + "," + 5 + ")");
                            tooltip.raise();
                        } else {
                            mouseLine.attr("opacity", "0").attr('d', '');
                            tooltip.attr("display", "none");
                        }
                    }
                }).on('mouseout', function () {
                    mouseLine.attr("opacity", "0").attr('d', '');
                    tooltip.attr("display", "none");
                });
            }

            var legendSpace = (config.width - (config.margin.left + config.margin.right)) / labels.length;
            var legend = svg.append('g').attr('class', 'legendGroup');

            var that = this;

            Object.entries(labels).forEach(function(d, i){
                var legendGroup = legend.append('g').attr('class', 'area_legend').attr('id', 'area_legend_' + id + '_' + i);
                legendGroup.selectAll(".areaLegend_" + id)
                    .append('g')
                    .attr('class', 'areaLegend_' + id)
                    .attr('transform', function (d, i) {
                        return 'translate(' + config.legendOrientation == 'left' ? config.xLeftLegendOrientationText : config.legendOrientation == 'right' ? config.width - config.xRightLegendOrientationText : (legendSpace / 2) + i * legendSpace + ',' + config.legendOrientation == 'top' ? config.yTopLegendOrientationText : config.legendOrientation == 'left' ? i * config.yLeftRightLegendOrientationText : config.height + ')';
                    });

                legendGroup.append("text")
                    .attr("x", config.legendOrientation == 'left' ? config.xLeftLegendOrientationText : config.legendOrientation == 'right' ? config.width - config.xRightLegendOrientationText : (legendSpace / 2) + i * legendSpace)
                    .attr("y", config.legendOrientation == 'top' ? config.yTopLegendOrientationText : config.legendOrientation == 'left' || config.legendOrientation == 'right' ? (i+0.3) * config.yLeftRightLegendOrientationText : (config.height - (config.margin.top + config.margin.bottom)) + 35)
                    .attr("class", "legend")
                    .attr('text-anchor', config.legendOrientation == 'left' ? 'end' : '')
                    .style('cursor', 'pointer')
                    .style("fill", config.color(i))
                    .text(d[1].text);

                legendGroup.append("rect")
                    .attr("fill", config.color(i))
                    .attr('x', config.legendOrientation == 'left' ? config.xLeftLegendOrientationLine : config.legendOrientation == 'right' ? config.width - config.xRightLegendOrientationLine : ((legendSpace / 2) + i * legendSpace) - 20)
                    .attr('y', config.legendOrientation == 'top' ? config.yTopLegendOrientationLine : config.legendOrientation == 'left' || config.legendOrientation == 'right' ? i * config.yLeftRightLegendOrientationLine : (config.height - (config.margin.top + config.margin.bottom)) + 29)
                    .attr("width", config.widthLegendOrientationLine)
                    .attr('height', config.heightLegendOrientationLine);

                if(config.enableClickLegend) {
                    d3.select('#area_legend_' +id + '_' + i).style('opacity', d[1].show ? '' : '0.5');
                    legendGroup.on("click", function () {
                        data.forEach(function (item, key) {
                            var formatFullDate = d3.timeFormat('%d/%m/%Y');
                            var formatFullDateAndTime = d3.timeFormat('%d/%m/%Y %H:%M');
                            if(config.timeFormat == '%H:%M'){
                                data[key].year = formatFullDateAndTime(data[key].year);
                            }else if(config.timeFormat != '%b/%Y' && config.timeFormat != '%m/%Y' && config.timeFormat != '%d'){
                                data[key].year = formatTime(data[key].year);
                            }else{
                                data[key].year = formatFullDate(data[key].year);
                            }
                        });
                        labels[i].show = !labels[i].show;

                        document.getElementById('chart_area_' + that.dataBlock.id).innerHTML = '';
                        that.chartArea(data, labels);
                    });

                    if (d.show == false) {
                        document.getElementById('area_legend_' + id + '_' + i).dispatchEvent(new Event('click'));
                    }
                }
            });
        }
    }
}
</script>