<template>
    <div :id="dataBlock.id" :style="dataBlock.style">
        <div class="definition-field-content" >
            <div class="definition-field-header">
                  <div class="definition-field_header-text T14">
                         {{dataBlock.title}}
                  </div>
                  <div class="link T14">
                        <div class="definition-field-icon-open" @click="GhOpenCloseField(dataBlock.id)">[ + ]</div>
                        <div class="definition-field-icon-close" style="display: none;" @click="GhOpenCloseField(dataBlock.id)">[ - ]</div>
                  </div>
                  <div v-if="dataBlock.subTitle != '' && dataBlock.subTitle !== undefined" class="T14" style="margin-left: 20px;" :style="(dataBlock.subStyle == '' || dataBlock.subStyle === undefined) ? 'color: black!important;' : dataBlock.subStyle">
                         {{dataBlock.subTitle}}
                  </div>
                  <div v-if="dataBlock.extraSubTitle != '' && dataBlock.extraSubTitle !== undefined" class="T14" style="margin-left: 20px;" :style="(dataBlock.extraSubStyle == '' || dataBlock.extraSubStyle === undefined) ? 'color: black!important;' : dataBlock.extraSubStyle">
                        {{dataBlock.extraSubTitle}}
                  </div>
                <div v-if="dataBlock.extraAction" class="T14" style="margin-left: 20px;" :style="(dataBlock.extraSubStyle == '' || dataBlock.extraSubStyle === undefined) ? 'color: black!important;' : dataBlock.extraSubStyle">
                    <slot name="actions"></slot>
                </div>
             </div>
        </div>
        <div class="definition-field-content-expansion" style="display: none">
            <div class="section" :style="dataBlock.contentStyle !== undefined ? dataBlock.contentStyle : ''">
                 <slot name="content"></slot>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "GhDropDownField",
    props:['dataBlock'],
    mounted() {
        if(this.dataBlock.isDrop == true){
          document.getElementById(this.dataBlock.id).getElementsByClassName('definition-field-icon-close')[0].style.display = "block";
          document.getElementById(this.dataBlock.id).getElementsByClassName('definition-field-icon-open')[0].style.display = "none";
          document.getElementById(this.dataBlock.id).getElementsByClassName('definition-field-content-expansion')[0].style.display = "block";
        }
    },
    methods:{
        GhOpenCloseField(id){
            let StateField = '';
            let StateIconOpen = '';
            let StateIconClose = '';
            if(document.getElementById(id).getElementsByClassName('definition-field-content-expansion')[0].style.display == 'none'){
                StateField = "block";
                StateIconOpen = 'none';
                StateIconClose = 'block';
            }else{
                StateField = "none";
                StateIconOpen = 'block';
                StateIconClose = 'none';
            }
            document.getElementById(id).getElementsByClassName('definition-field-icon-close')[0].style.display = StateIconClose;
            document.getElementById(id).getElementsByClassName('definition-field-icon-open')[0].style.display = StateIconOpen;
            document.getElementById(id).getElementsByClassName('definition-field-content-expansion')[0].style.display = StateField;
        }
    }
}
</script>
<style>
</style>
