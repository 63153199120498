<template>
    <div>
        <div id="b-1" class="nav-active-background"></div>
        <div :id="'tap' + dataBlock.id" class="bisel-active link" @mouseover="taphover(id,'hover');" @mouseout="taphover(id,'out');">
            <div class="bisel-active-left"></div>
            <div class="bisel-active-center"></div>
            <div class="bisel-active-right"></div>
        </div>
        <div class="tabs-navigation">
            <div class="tabs-content">
                <div
                    v-for="i in items"
                    :key="i.id"
                    class="tabs-item C7 link"
                    :id="i.id+dataBlock.id"
                    @click="ghactivesubtab(i.id,i.value);"
                    @mouseover="taphover(`${i.id+dataBlock.id}`,'hover');"
                    @mouseout="taphover(`${i.id+dataBlock.id}`,'out');"
                >
                    <div class="tabs-label C1 F2" :style="i.warning && flicker ? 'display: flex' : ''">
                        {{$t(i.value)}}
                        <div style="width: 8px; height: 8px; margin-top: 3px; margin-left: 6px;" v-if="i.warning && flicker">
                            <span :class="{'flicker': flicker}"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "GhSubTab",
        props:['items','dataBlock'],
        data(){
            return{
                interval: 0,
                destiny: 0,
                id: localStorage.getItem(this.dataBlock.id) ? localStorage.getItem(this.dataBlock.id) : this.items[0].id + this.dataBlock.id,
                data_selected: localStorage.getItem(this.dataBlock.id + '_selected') ? JSON.parse(localStorage.getItem(this.dataBlock.id + '_selected')) : this.items[0],
                flicker: false,
                activeId: null,
                observer: null
            }
        },
        mounted() {
            const tapElement = document.getElementById('tap' + this.dataBlock.id);
            const activeTabElement = document.getElementById(this.id);

            this.activeId = this.id.split(this.dataBlock.id)[0];

            if (tapElement && activeTabElement) {
                window.addEventListener('resize', this.handleresize);

                this.observer = new ResizeObserver(this.handleScroll);

                const contenedor = document.body;

                this.observer.observe(contenedor);

                this.activedsubtab(this.id);


                const dispatchData = {
                    value: this.data_selected.value
                };

                if (this.data_selected.call !== null && this.data_selected.call !== undefined) {
                    dispatchData.id = this.data_selected.id;
                    dispatchData.call = this.data_selected.call;
                }

                this.$store.dispatch(this.dataBlock.calldispatch, dispatchData, { root: true });

                this.items.forEach(({ id }) => {
                    const itemElement = document.getElementById(id + this.dataBlock.id);
                    if (itemElement) {
                        const width = itemElement.getBoundingClientRect().width + 20;
                        itemElement.style.width = width + 'px';
                    }
                });

                const activeCenterElement = document.querySelector('.bisel-active > .bisel-active-center');

                if (activeCenterElement) {
                    const tabWidth = activeTabElement.offsetWidth;
                    activeCenterElement.style.width = tabWidth - 8 + 'px';

                    const activeTabLeft = activeTabElement.getBoundingClientRect().left;
                    const tapLeft = tapElement.getBoundingClientRect().left;
                    tapElement.style.left = (activeTabLeft - tapLeft) + 12 + 'px';
                }
            }
        },
        methods: {
            handleScroll(){
                const tapElement = document.getElementById('tap' + this.dataBlock.id);
                const activeTabElement = document.getElementById(this.id);

                const scrollWidth = window.innerWidth - document.documentElement.clientWidth;

                if (tapElement && activeTabElement) {
                    const activeCenterElement = document.querySelector('.bisel-active > .bisel-active-center');
                    if (activeCenterElement && this.activeId !== null) {
                        this.destiny = document.getElementById(this.activeId + this.dataBlock.id).offsetLeft;

                        let newSize = 0;
                        if(document.documentElement.scrollHeight > document.documentElement.clientHeight){
                            newSize = this.destiny - (scrollWidth/25);
                        }else{
                            newSize = this.destiny;
                        }
                        tapElement.style.left = newSize + 'px';
                    }
                }
            },
            handleresize() {
                this.destiny = document.getElementById(this.activeId + this.dataBlock.id).offsetLeft - document.documentElement.scrollHeight > document.documentElement.clientHeight;
                this.id = this.activeId + this.dataBlock.id;

                this.activedsubtab(this.id);


                document.querySelector('.bisel-active > .bisel-active-center').animate([{
                    width: document.getElementById(this.id).clientWidth - 8 + 'px'
                }, ], {
                    duration: 0,
                    fill: 'forwards'
                });
            },
            taphover(id, state) {
                const element = document.getElementById(id);
                const label = element.querySelector('.tabs-label');

                if (state === 'hover') {
                    element.style.backgroundColor = '#CEDAE6';
                    label.classList.add("F5", "C6");
                    if (this.id !== id) {
                        label.classList.add("F4");
                    }
                } else if (state === 'out') {
                    label.classList.remove("F5", "C6");
                    if (this.id !== id) {
                        label.classList.remove("F4");
                    }
                    element.style.removeProperty('background-color');
                }
            },
            activedsubtab(id) {
                const tabToActivate = document.getElementById(id);
                if (tabToActivate) {
                    tabToActivate.querySelector('.tabs-label').classList.add("F4");

                    this.items.forEach((item) => {
                        if (id !== item.id + this.dataBlock.id) {
                            const tabLabelToRemove = document.getElementById(item.id + this.dataBlock.id);
                            if (tabLabelToRemove) {
                                tabLabelToRemove.querySelector('.tabs-label').classList.remove("F4");
                            }
                        }
                    });
                }
            },
            ghactivesubtab(id, value) {
                this.activeId = id;
                localStorage.setItem(this.dataBlock.id, id + this.dataBlock.id);
                localStorage.setItem(this.dataBlock.id + '_selected', JSON.stringify(this.items[this.items.findIndex((data) => data.id === id)]));

                this.destiny = document.getElementById(this.activeId + this.dataBlock.id).offsetLeft - document.documentElement.scrollHeight > document.documentElement.clientHeight;
                this.id = id + this.dataBlock.id;
                this.activedsubtab(this.id);

                let callValue = '';
                const index = this.items.findIndex((data) => data.id === id);
                if (index !== -1) {
                    const item = this.items[index];
                    if (item.call !== null && item.call !== undefined) {
                        callValue = item.call;
                    }
                }

                this.$store.dispatch(
                    this.dataBlock.calldispatch, {
                        id: id,
                        value: value,
                        call: callValue
                    }, {
                        root: true
                    }
                );

                document.querySelector('.bisel-active > .bisel-active-center').animate([{
                    width: document.getElementById(this.id).clientWidth - 8 + 'px'
                }, ], {
                    duration: 0,
                    fill: 'forwards'
                });

                const tapElement = document.getElementById('tap' + this.dataBlock.id);
                const activeTabElement = document.getElementById(this.id);

                this.activeId = this.id.split(this.dataBlock.id)[0];

                window.removeEventListener('resize', this.handleresize);
                this.observer.disconnect();

                if (tapElement && activeTabElement) {
                    window.addEventListener('resize', this.handleresize);

                    this.observer = new ResizeObserver(this.handleScroll);

                    const contenedor = document.body;

                    this.observer.observe(contenedor);

                    this.activedsubtab(this.id);

                    const activeCenterElement = document.querySelector('.bisel-active > .bisel-active-center');

                    if (activeCenterElement) {
                        const tabWidth = activeTabElement.offsetWidth;
                        activeCenterElement.style.width = tabWidth - 8 + 'px';

                        const activeTabLeft = activeTabElement.getBoundingClientRect().left;
                        const tapLeft = tapElement.getBoundingClientRect().left;
                        tapElement.style.left = (activeTabLeft - tapLeft) + 12 + 'px';
                    }
                }
            },
            startFlicker() {
                this.flicker = !this.flicker;
            },
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.handleresize);
            this.observer.disconnect();
        }
    }
</script>