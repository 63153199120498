<template>
    <div :style="[dataBlock.style, { display: 'block', minWidth: '1024px' }]" :class="dataBlock.class">
        <div style="display: flex;">
            <div :id="dataBlock.id + '_block_selector'" style="display: flex; margin-top: 1px;">
                <label :id="dataBlock.id + '_selector_label'" class="T14" :style="dataBlock.label && dataBlock.label.style" :class="dataBlock.label && dataBlock.label.class" style="color: black !important;">{{ dataBlock.text }}</label>
                <div :id="dataBlock.id + '_block_selector_operators'" style="cursor: pointer;">
                    <div :id="dataBlock.id + '_children_icon_toggle'" @click="openSecondWindow" :style="[dataBlock.childicon && dataBlock.childicon.style, { whiteSpace: 'nowrap' }]" :class="[toggleChildrenIcon, dataBlock.childicon && dataBlock.childicon.class, 'T19']">{{ updatesummary ? '[ - ]' : '[ + ]' }}</div>
                </div>
            </div>
            <div :id="dataBlock.id + '_selector_SUMMARY'" class="gh_data_selector-summary T15" style="margin-top: 1px;">{{ summaryData.length > 0 ? summaryData : '' }}</div>
            <input v-if="dataBlock.required" type="hidden" :label_required="dataBlock.text" :value="summaryData !== '' ? 1 : 0">
            <div :id="dataBlock.id + '_datasearch_select'" v-if="selectedData.length !== 0">
                <input v-for="(i, index) in selectedData" :key="i.id" :name="dataBlock.nameResult + '[' + index + ']'" type="hidden" :value="i.id">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "GhSearchWindow",
        props: ['dataBlock', 'mainSelector', 'tableResolt', 'tableSelected'],
        data() {
            return {
                selectedData: [],
                disabledData: [],
                updatesummary: false,
                windowselected: null,
                summaryData: '',
                headersearch: [],
                headerselected: [],
                data: JSON.stringify(this.tableResolt.data),
            }
        },
        computed: {
            toggleChildrenIcon() {
                return this.updatesummary ? 'gh_data_selector-less' : 'gh_data_selector-more';
            }
        },
        mounted() {
            this.headersearch = [...this.tableResolt.header];
            this.headerselected = [...this.tableResolt.header];

            const actionsIndexSearch = this.headersearch.findIndex(data => data.field === 'actions');
            if (actionsIndexSearch !== -1 && (!this.tableSelected.actionsSearch || this.tableSelected.actionsSearch.length === 0)) {
                this.headersearch.splice(actionsIndexSearch, 1);
            }

            const actionsIndexSelected = this.headerselected.findIndex(data => data.field === 'actions');
            if (actionsIndexSelected !== -1 && (!this.tableSelected.actionsSelected || this.tableSelected.actionsSelected.length === 0)) {
                this.headerselected.splice(actionsIndexSelected, 1);
            }

            if (this.tableSelected && this.tableSelected.selected && this.tableSelected.selected.length !== 0) {
                this.selectedData = this.tableSelected.selected;

                if (this.tableResolt.type !== 'radio') {
                    this.selectedData.forEach(select => {
                        select['check'] = true;
                        const dataIndex = this.tableResolt.data.findIndex(item => item.id === select.id);
                        if (dataIndex !== -1) {
                            this.$set(this.tableResolt.data, dataIndex, { ...this.tableResolt.data[dataIndex], check: true });
                        }
                    });
                } else {
                    const data = this.selectedData[0];
                    this.selectedData = [data];
                    this.selectedData[0]['check'] = true;
                    const dataIndex = this.tableResolt.data.findIndex(item => item.id === this.selectedData[0].id);
                    if (dataIndex !== -1) {
                        this.$set(this.tableResolt.data, dataIndex, { ...this.tableResolt.data[dataIndex], check: true });
                    }
                }

                this.setSummary();
            }
        },
        methods: {
            reloadwindow() {
                this.updatesummary = true;
            },
            closewindow() {
                this.updatesummary = false;
            },
            updateSelect(data) {
                this.selectedData = data;
                this.selectedData.length !== 0 ? this.setSummary() : this.summaryData = '';
            },
            openSecondWindow(){
                var then = this;
                if (then.windowselected && then.windowselected.parent) {
                    then.windowselected.parent.location.reload();
                }
                this.updatesummary = true;

                this.params = {
                    idblock: this.dataBlock.id,
                    actionsSearch: this.tableSelected.actionsSearch ? this.tableSelected.actionsSearch : '',
                    actionsSelected: this.tableSelected.actionsSelected ? this.tableSelected.actionsSelected : '',
                    actionsNoDropSelected: this.tableSelected.actionsNoDropSelected ? this.tableSelected.actionsNoDropSelected : '',
                    actionsNoDropSearch: this.tableSelected.actionsNoDropSearch ? this.tableSelected.actionsNoDropSearch : '',
                    allmarkoption: this.dataBlock.allmarkoption?this.dataBlock.allmarkoption:false,
                    textuserselected: this.dataBlock.textuserselected ? this.dataBlock.textuserselected : this.$t('selected_users'),
                    img: this.dataBlock.imagelocation ? this.dataBlock.imagelocation : '',
                    textNofilter: this.dataBlock.textNofilter ? this.dataBlock.textNofilter : this.$t('no_applied_filter'),
                    textNoResult: this.dataBlock.textNoResult ? this.dataBlock.textNoResult : this.$t('no_results_found'),
                    nameSlot: this.mainSelector.name,
                    nameStore: this.dataBlock.nameStore,
                    supportPermision: this.dataBlock.supportPermision ? this.dataBlock.supportPermision : false,
                    ResultSearch: this.dataBlock.ResultSearch ? this.dataBlock.ResultSearch : 'ResultSearch',
                    dataSelected: this.selectedData,
                    dataDisabled: this.tableSelected !== undefined && this.tableSelected.disabled !== undefined ? this.tableSelected.disabled : [],
                    loader: this.dataBlock !== undefined && this.dataBlock.loader !== undefined ? this.dataBlock.loader : '',
                    actionresult: this.dataBlock.actionresult,
                    textlocation: this.dataBlock.textlocation ? this.dataBlock.textlocation : this.$t('add_users'),
                    headersearch: JSON.stringify(this.headersearch),
                    headerselected: JSON.stringify(this.headerselected),
                    formId: this.mainSelector.formId,
                    actionsearch: this.mainSelector.actionsearch,
                    checkfilterselected: this.dataBlock.checkfilterselected && (this.dataBlock.checkfilterselected === 1 || this.dataBlock.checkfilterselected === 2) && !isNaN(Number(this.dataBlock.checkfilterselected)) ? this.dataBlock.checkfilterselected : 1,
                    hiddencheck: this.dataBlock.hiddencheck ? this.dataBlock.hiddencheck : false,
                    type: this.tableResolt.type ? this.tableResolt.type : 'checkbox',
                }

                var dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : screen.left;
                var dualScreenTop = window.screenTop !== undefined ? window.screenTop : screen.top;
                var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
                var height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;
                var left = ((width / 2) - (1278 / 2)) + dualScreenLeft;
                var top = ((height / 2) - (800 / 2)) + dualScreenTop;
                var extra_css = top + ',left=' + left;
                extra_css += ',resisable=no';
                var style = this.mainSelector.style ? this.mainSelector.style : ['width=' + 1278,'height=' + 800,'resizable=yes','scrollbars=yes','status=yes'].join(',');

                if(!this.mainSelector.style){
                    if('undefined' != typeof extra_css){
                        style += ',top=' + extra_css;
                    }
                }

                var windowRef = this.Global.openSecondWindow('GhContentWindowSearchView',{idblock: this.params.idblock});

                localStorage.setItem('Params_' + this.dataBlock.id, JSON.stringify(this.params));

                window.UpdatedSelect = function (e) {then.updateSelect(e);}
                window.reloadwindow = function () {then.reloadwindow();}
                window.closewindow = function () {then.closewindow();}

                window.events = function (e, d) {
                    d === undefined ? then.$emit(e) : '';
                    d !== undefined ? then.$emit(e, d) : '';
                }

                this.windowselected = window.open(windowRef.href, this.dataBlock.id + 'content_search', style);

                window.addEventListener("beforeunload", function() {
                    then.windowselected.close();
                    localStorage.getItem('Params_' + then.dataBlock.id) ? localStorage.removeItem('Params_' + then.dataBlock.id) : '';
                });
            },
            setSummary() {
                const summaryKeys = Object.entries(this.tableSelected.headersummary);
                let summary = '';
                const selectedData = Array.isArray(this.selectedData) ? this.selectedData : [this.selectedData];
                selectedData.forEach((item) => {
                    summaryKeys.forEach((key) => {
                        const keyPath = Array.isArray(key[1]) ? key[1] : [key[1]];
                        keyPath.forEach((subKey) => {
                            let value = item;
                            subKey.split('.').forEach((property) => {
                                value = value[property];
                            });
                            if (value !== undefined) {
                                summary += value + ' ';
                            }
                        });
                    });
                    summary = summary.slice(0, -1) + ', ';
                });
                this.summaryData = summary.slice(0, -2);
            }
        },
        destroyed() {
            localStorage.getItem('Params_' + this.dataBlock.id) ? localStorage.removeItem('Params_' + this.dataBlock.id) : '';
        }
    }
</script>