<template>
    <div :id="dataBlock.id + '_general_schedule_calendar'">
        <div style="display: flex;">
            <div :style="widthOtherLine != 0 ? 'min-width:' + widthOtherLine + 'px !important;' : ''" :id="dataBlock.id + '_pre_schedule_calendar'" class="T15_b" style="width: 10%;padding-right: 25px;display: flex;align-items: center;justify-content: end;">{{$t('AM')}}</div>
            <div style="display: flex;width: auto">
                <div v-for="(item,index) in data.am" :key="'a_p_' + index" style="padding-left: 5px;padding-right: 5px;">
                    <div v-for="(subItem,subIndex) in item" :key="'a_s_' + subIndex" :style="'background-color:' + dataBlock.colors[subItem] + ';height:10px;width: ' + widthHour + 'px;margin-bottom: 2px;'">

                    </div>
                </div>
            </div>
            <div style="width: auto;"></div>
        </div>
        <div :id="dataBlock.id + '_days_schedule_calendar'" style="display: flex;">
            <div v-if="data.prevMonth !== undefined && data.prevMonth.name != ''" :id="dataBlock.id + '_receptor_pre_schedule_calendar'" class="T15_b" :style="'width: 10%;text-align: right;cursor:pointer;min-width: ' + widthPreLine + 'px;white-space: nowrap;'" @click="$emit('prevM')">{{data.prevMonth.name + ' &#60; '}}</div>
            <div v-else :id="dataBlock.id + '_receptor_pre_schedule_calendar'" class="T15_b" :style="'width: 10%;text-align: right;min-width: ' + widthPreLine + 'px;white-space: nowrap;'"></div>
            <div :id="dataBlock.id + '_schedule_calendar'" style="display: flex;width: auto">
                <div v-for="(item,index) in data.days" :key="'b_p_' + index" style="padding-left: 5px;padding-right: 5px;display: flex;">
                    <div class="T15" :style="'width: ' + widthHour + 'px;cursor:pointer;z-index: 10;background-color: ' + (dataBlock.backgroundColorNumber !== undefined && dataBlock.backgroundColorNumber != '' ? dataBlock.backgroundColorNumber : 'white') + ';text-align: center'" @click="$emit('days',item)">{{item.day}}</div>
                </div>
            </div>
            <div v-if="data.nextMonth !== undefined && data.nextMonth.name != ''" class="T15_b" style="width: auto;text-align: left;cursor:pointer;white-space: nowrap;" @click="$emit('nextM')">{{' &#62; ' + data.nextMonth.name}}</div>
            <div v-else class="T15_b" style="width: auto;text-align: left;white-space: nowrap;"></div>
        </div>
        <div :style="'display: flex;margin-top: -' + marginLine + 'px;margin-bottom: ' + marginLine + 'px;'">
            <div :style="'width: ' + widthPreLine + 'px;min-width: ' + widthPreLine + 'px;'"></div>
            <div :style="'width: ' + widthLine + 'px;min-width: ' + widthLine + 'px;border-top: 1px solid black;'">
                <div v-for="(item,index) in data.days" :key="'c_p_' + index" style="padding-left: 5px;padding-right: 5px;display: flex;">
                    <div class="T15" :style="'width: ' + widthHour + 'px;'" :key="item.year + '_' + item.month + '_' + item.day"></div>
                </div>
            </div>
            <div style="width: auto;"></div>
        </div>
        <div style="display: flex;">
            <div :style="widthOtherLine != 0 ? 'min-width:' + widthOtherLine + 'px !important;' : ''" class="T15_b" style="width: 10%;padding-right: 25px;display: flex;align-items: center;justify-content: end;">{{$t('PM')}}</div>
            <div style="display: flex;width: auto">
                <div v-for="(item,index) in data.pm" :key="'d_p_' + index" style="padding-left: 5px;padding-right: 5px;">
                    <div v-for="(subItem,subIndex) in item" :key="'d_s_' + subIndex" :style="'background-color:' + dataBlock.colors[subItem] + ';height:10px;width: ' + widthHour + 'px;margin-bottom: 2px;'">

                    </div>
                </div>
            </div>
            <div style="width: auto;"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "GhScheduleCalendar",
        props: ["dataBlock", "data"],
        data(){
            return{
                marginLine: 0,
                widthLine: 0,
                widthPreLine: 0,
                widthOtherLine: 0,
                widthHour: this.dataBlock.widthHour !== undefined && this.dataBlock.widthHour != '' ? (this.dataBlock.widthHour < 16 ? 16 : this.dataBlock.widthHour) : 30,
                originalWidth: 0,
            }
        },
        mounted(){
            this.marginLine = document.getElementById(this.dataBlock.id + '_schedule_calendar').clientHeight/2;
            this.widthLine = document.getElementById(this.dataBlock.id + '_schedule_calendar').clientWidth;
            this.widthPreLine = document.getElementById(this.dataBlock.id + '_pre_schedule_calendar').clientWidth;
            // var general_schedule_calendar = document.getElementById(this.dataBlock.id + '_general_schedule_calendar').scrollWidth;
            var general_schedule_calendar = document.getElementById(this.dataBlock.id + '_days_schedule_calendar').scrollWidth;
            // var schedule = document.getElementById(this.dataBlock.id + '_schedule_calendar').clientWidth;

            //console.log(general_schedule_calendar,schedule,general_schedule_calendar-schedule);

            this.originalWidth = general_schedule_calendar;
            // this.resizeSchedule();
        },
        created() {
            window.addEventListener("resize", this.myEventHandler);
        },
        destroyed() {
            window.removeEventListener("resize", this.myEventHandler);
        },
        methods: {
            myEventHandler(e) {
                if(e.type == 'resize'){
                    this.resizeSchedule();
                }
            },
            resizeSchedule(){
                var text = document.getElementById(this.dataBlock.id + '_receptor_pre_schedule_calendar').innerHTML;
                var canvas = document.createElement("canvas");
                var ctx = canvas.getContext('2d');
                var receptor_pre_schedule = ctx.measureText(text);

                var pre_schedule = document.getElementById(this.dataBlock.id + '_pre_schedule_calendar').clientWidth;
                this.widthLine = document.getElementById(this.dataBlock.id + '_schedule_calendar').clientWidth;
                this.widthPreLine = document.getElementById(this.dataBlock.id + '_pre_schedule_calendar').clientWidth;

                if(pre_schedule < (receptor_pre_schedule.width + 1)){
                    this.widthPreLine = receptor_pre_schedule.width + 3;
                    this.widthOtherLine = receptor_pre_schedule.width + 3;
                }

                // if(window.outerWidth < this.originalWidth){
                //     this.widthHour = 16;
                // }else{
                //     console.log(Math.round(this.widthLine/Object.keys(this.data.days).length));
                //     console.log(Math.round(this.originalWidth/Object.keys(this.data.days).length));
                //     console.log(Math.round(window.outerWidth/Object.keys(this.data.days).length));
                //     this.widthHour = 30;
                //     this.widthHour = Math.round(this.originalWidth/Object.keys(this.data.days).length);
                // }

                canvas.remove();
            }
        }
    }
</script>