<template>
    <div>
        <div id="selectors" :style="'display: flex;border-radius: 20px;width: max-content;;background-color: ' + dataBlock.colors.nonActive">
            <div v-for="(item, index) in data"
                 :key="index"
                 :style="
                     'background-color: ' + (index == active ? dataBlock.colors.active : dataBlock.colors.nonActive) + ';' +
                     'color: ' + (index == active ? dataBlock.colors.textActive : dataBlock.colors.textNonActive) + ';' +
                     'padding-bottom: 5px;' +
                     'padding-top: 5px;' +
                     'padding-left: 40px;' +
                     'padding-right: 40px;' +
                     'cursor: pointer;' +
                     'border-radius: 20px;' +
                     'transition: background-color 0.7s ease;'
                 "
                 @click="changeActive(index)">
                    {{item.name}}
            </div>
        </div>
        <div id="templates">
            <component :is="componentActive"></component>
        </div>
    </div>
</template>

<script>
    export default {
        name: "GhSlideSelector",
        props: ["dataBlock", "data"],
        data(){
            return{
                active: this.dataBlock.startActive !== undefined ? this.dataBlock.startActive : 0,
                componentActive: this.data[(this.dataBlock.startActive !== undefined ? this.dataBlock.startActive : 0)]['template']
            }
        },
        methods:{
            changeActive(index){
                this.active = index;
                this.componentActive = this.data[index]['template'];
            }
        }
    }
</script>