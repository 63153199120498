<template>
    <div class="chart-container">
        <div :id="'chart_bar_vertical_' + dataBlock.id" :style="dataBlock.style ? dataBlock.style : ''" :class="dataBlock.class ? dataBlock.class + 'radarChart' : 'radarChart'" style=" "></div>
    </div>
</template>
<script>
import * as d3 from 'd3';
export default {
    name: "GhCharBarVertical",
    props: ['dataBlock', 'chartData', 'chartConfig', "color"],
    data(){
        return{
            width: 0,
            height: 0,
            Data: this.chartData,
            configure: new Array(),
            allfalse: false,
            modebar: this.changemodebar,
            percent: this.changepercent,
            maxValue: 0,
            refreshData: 0
        }
    },
    mounted() {
        this.generateChart();
    },
    computed:{
        changepercent(){
            return this.chartConfig && this.chartConfig.percentage && this.chartConfig.percentage == true ? this.chartConfig.percentage : false;
        },
        changemodebar(){
            return this.dataBlock && this.dataBlock.multibar ? this.dataBlock.multibar : false
        }
    },
    watch:{
        'refreshData'(){
            if(document.getElementById('chart_bar_vertical_' + this.dataBlock.id).children[0] !== undefined) {
                document.getElementById('chart_bar_vertical_' + this.dataBlock.id).children[0].remove();
            }
            this.generateChart();
        },
        changepercent(){
            this.maxValue = this.chartConfig && this.chartConfig.maxRange ? this.changepercent == true ? 100:this.chartConfig.maxRange : d3.max(this.configure.maxedarray, d => this.changepercent==true ? 100 : d);
            this.configure.y = d3.scaleLinear().range([this.configure.h,0]).domain([0,this.maxValue]);
            this.configure.yAxis = d3.axisLeft().scale(this.configure.y).ticks(this.configure.ticksy).tickSizeInner(-this.configure.w).tickPadding(this.configure.tickPadding);
            document.getElementById('chart_bar_vertical_' + this.dataBlock.id).innerHTML = '';
            this.verticalbar('#chart_bar_vertical_' + this.dataBlock.id, this.configure, this.Data);
        },
        changemodebar(){}
    },
    methods:{
        generateChart(){
            var left = this.chartConfig && this.chartConfig.legend && this.chartConfig.legend.orientation == 'left' ? 150 : 0;
            var margin = {
                top: this.chartConfig && this.chartConfig.margin && this.chartConfig.margin.top && !isNaN(Number(this.chartConfig.margin.top)) ? this.chartConfig.margin.top : 60,
                bottom: this.chartConfig && this.chartConfig.margin && this.chartConfig.margin.bottom && !isNaN(Number(this.chartConfig.margin.bottom)) ? this.chartConfig.margin.bottom : 60,
                right: this.chartConfig && this.chartConfig.margin && this.chartConfig.margin.right && !isNaN(Number(this.chartConfig.margin.right)) ? this.chartConfig.margin.right : 150,
                left: this.chartConfig && this.chartConfig.margin && this.chartConfig.margin.left && !isNaN(Number(this.chartConfig.margin.left)) ? this.chartConfig.margin.left : 50 + left,
                leftSVG: this.chartConfig && this.chartConfig.margin && this.chartConfig.margin.leftSVG && !isNaN(Number(this.chartConfig.margin.leftSVG)) ? this.chartConfig.margin.leftSVG : 0,
            }
            var paddingInner = this.chartConfig && this.chartConfig.bar && this.chartConfig.bar.paddingInner ? this.chartConfig.bar.paddingInner : 0.41;
            var valuewidth = this.chartConfig && this.chartConfig.width ? this.chartConfig.width : 890;
            var valueheight = this.chartConfig && this.chartConfig.height ? this.chartConfig.height : 710;
            var width = valuewidth - margin.left- margin.right;
            var height = valueheight- margin.top - margin.bottom;
            this.width = width;
            this.height = height;
            var xtextlabelorientation = this.chartConfig && this.chartConfig.tooltip && this.chartConfig.tooltip.margin && (this.chartConfig.tooltip.margin.xtextlabelorientation == 'mid' || this.chartConfig.tooltip.margin.xtextlabelorientation=='left') ? this.chartConfig.tooltip.margin.xtextlabelorientation : 'left';
            var colorbackground = this.chartConfig && this.chartConfig.tooltip && this.chartConfig.tooltip.colorBackground ? this.chartConfig.tooltip.colorBackground : '#DDDDDD';
            var colorstroke = this.chartConfig && this.chartConfig.tooltip && this.chartConfig.tooltip.colorStroke ? this.chartConfig.tooltip.colorStroke:colorbackground;
            var parseDate = d3.timeParse(this.dataBlock.parsedate ? this.dataBlock.parsedate : '%d/%m/%Y');
            var parseFormat = d3.timeFormat(this.dataBlock.parseformat ? this.dataBlock.parseformat : '%b');
            var auxchartdata = new Array();
            var color = d3.scaleOrdinal().domain(this.chartData).range(this.color ? this.color : ["red","blue","green","black"]);
            var paddingbar = this.chartConfig && this.chartConfig.bar && this.chartConfig.bar.paddingbar ? this.chartConfig.bar.paddingbar : 0.07;

            var extraData = new Array();
            this.Data = new Array();

            this.chartData.forEach(function(d) {
                if(extraData.length == 0){
                    extraData = d;
                }else{
                    extraData = d;
                }
            });

            if (this.dataBlock.datemode==true){
                this.chartData.forEach(function (d) {
                    d.values.forEach(function (d) {
                        d.label = parseDate(d.label);
                    });
                });

                this.chartData.forEach((d) => {
                    d.values.forEach((val) => {
                        val.label = parseFormat(val.label)
                    });
                });
            }

            var maxedarray = new Array();
            this.chartData.forEach(function(d) {
                var yearSum = d3.sum(d.values, function(d){
                    return d.value;
                });
                maxedarray.push(yearSum);
            });

            var legendkey = new Array();
            this.chartData.forEach((data)=>{
                if (data.show==true){
                    legendkey.push(data.name)
                }
            });

            var stack = d3.stack().keys(legendkey);

            if (this.dataBlock.multibar!==true || !this.dataBlock.multibar) {
                datenew = new Array();
                extraData.values.forEach((d)=>{
                    datenew.push({name:d.label})
                });

                extraData.values.forEach((d)=>{
                    auxchartdata.push({name:d.label})
                });

                auxchartdata.forEach((d)=>{
                    this.chartData.forEach((e,i)=>{
                        d[e.name] = e.values[this.chartData[i].values.findIndex((data)=>data.label==d.name)].value
                    });
                });

                datenew.forEach((d)=>{
                    this.chartData.forEach((e,i)=>{
                        if(e.show==true){
                            d[e.name] = e.values[this.chartData[i].values.findIndex((data)=>data.label==d.name)].value
                        }
                    });
                });

                var x = d3.scaleBand().range([0, width],.5).padding(paddingbar).paddingInner(paddingInner).domain(extraData.values.map(function (d) {return d.label;}));
                var x0 = d3.scaleBand().rangeRound([0, width]).domain(extraData.values.map(function (d) {return d.label;}));
                var x1 = d3.scaleBand().domain(extraData.values.map(function (d) {return d.label;})).rangeRound([0, x0.bandwidth()]);
                var xAxis = d3.axisBottom().scale(x);
                this.Data = stack(datenew);
            }

            if (this.dataBlock.multibar == true){
                var datenew = new Array();
                extraData.values.forEach((d)=>{
                    datenew.push({name:d.label})
                });

                datenew.forEach((d)=>{
                    d['values'] = new Array()
                });

                datenew.forEach((d)=>{
                    this.chartData.forEach((e,i)=>{
                        if (e.show==true) {
                            d['values'].push({'name':d.name,'label': e.name,'value': e.values[this.chartData[i].values.findIndex((data) => data.label == d.name)].value})
                        }
                    });
                });

                x0  = d3.scaleBand().rangeRound([0, width], .5).paddingInner(paddingInner).domain(datenew.map(function(d) { return d.name; }));
                x1  = d3.scaleBand().padding(paddingbar).domain(datenew[0].values.map(function(d) { return d.label; })).rangeRound([0, x0.bandwidth()]);
                xAxis = d3.axisBottom().scale(x0).tickValues(datenew.map(d=>d.name));

                datenew.forEach((date)=>{
                    date.values.forEach((val,i)=>{val['color']=color[i]})
                });

                maxedarray = new Array();

                var that = this;
                datenew.forEach(function(d) {
                    var yearSum = '';
                    if(that.dataBlock.showMax){
                        yearSum = d3.max(d.values, function (d) {
                            return d.value;
                        });
                    }else {
                        yearSum = d3.sum(d.values, function (d) {
                            return d.value;
                        });
                    }
                    maxedarray.push(yearSum);
                });

                var listordername = [];
                this.chartData.forEach((data) => {
                    listordername.push({'name': data.name})
                });
                this.Data = datenew;
            }

            this.maxValue = this.chartConfig && this.chartConfig.maxRange ? this.chartConfig && this.chartConfig.percentage == true ? 100 : this.chartConfig.maxRange : d3.max(maxedarray, d=> this.chartConfig && this.chartConfig.percentage == true ? 100 : d);

            var y = d3.scaleLinear().range([height,0]).domain([0, this.maxValue ]);
            var yAxis = d3.axisLeft().scale(y).ticks(this.chartConfig && this.chartConfig.ticksY ? this.chartConfig.ticksY : 6).tickSizeInner(-this.width).tickPadding(this.chartConfig && this.chartConfig.tickPadding ? this.chartConfig.tickPadding : 10);

            this.configure = {
                auxchartdata: auxchartdata,
                w: this.width,
                h: this.height,
                chartdata: this.chartData,
                maxedarray: maxedarray,
                margin: margin,
                maxValue: this.maxValue ,
                titlemode: this.dataBlock.titlemode ? this.dataBlock.titlemode : false,
                extravalues: extraData,
                imgmode: this.dataBlock.imgmode?this.dataBlock.imgmode:false,
                listordername: listordername,
                datenew: datenew,
                percent: this.chartConfig && this.chartConfig.percentage == true ? true : false,
                x: x,
                color: color,
                y: y,
                tickPadding: this.chartConfig && this.chartConfig.tickPadding ? this.chartConfig.tickPadding : 10,
                x0: x0,
                ticksy: this.chartConfig && this.chartConfig.ticksY ? this.chartConfig.ticksY : 6,
                x1: x1,
                xAxis: xAxis,
                yAxis: yAxis,
                multibar: this.dataBlock.multibar ? this.dataBlock.multibar : false,
                showMax: this.dataBlock.showMax ? this.dataBlock.showMax : false,
                title: {
                    name: this.chartConfig && this.chartConfig.title && this.chartConfig.title.name ? this.chartConfig.title.name : '',
                    x: this.chartConfig && this.chartConfig.title && this.chartConfig.title.x ? this.chartConfig.title.x:this.width / 2,
                    y: this.chartConfig && this.chartConfig.title && this.chartConfig.title.y ? this.chartConfig.title.y:margin.top / 2,
                    style: this.chartConfig && this.chartConfig.title && this.chartConfig.title.style ? this.chartConfig.title.style : '',
                    class: this.chartConfig && this.chartConfig.title && this.chartConfig.title.class ? this.chartConfig.title.class : '',
                },
                legend:{
                    stylerect:this.chartConfig && this.chartConfig.legend && this.chartConfig.legend.styles && this.chartConfig.legend.styles.rect && this.chartConfig.legend.styles.rect.style ? this.chartConfig.legend.styles.rect.style:'',
                    styletext:this.chartConfig && this.chartConfig.legend && this.chartConfig.legend.styles && this.chartConfig.legend.styles.text && this.chartConfig.legend.styles.text.style ? this.chartConfig.legend.styles.text.style:'',
                    classrect:this.chartConfig && this.chartConfig.legend && this.chartConfig.legend.styles && this.chartConfig.legend.styles.rect && this.chartConfig.legend.styles.rect.class ? this.chartConfig.legend.styles.rect.class:'',
                    classtext:this.chartConfig && this.chartConfig.legend && this.chartConfig.legend.styles && this.chartConfig.legend.styles.text && this.chartConfig.legend.styles.text.class ? this.chartConfig.legend.styles.text.class:'',
                    orientation:this.chartConfig && this.chartConfig.legend && this.chartConfig.legend.orientation?this.chartConfig.legend.orientation:"bottom",
                    xLeftLegendOrientationText: this.chartConfig !== undefined && this.chartConfig.legend && this.chartConfig.legend.xLeftLegendOrientationText && this.chartConfig.legend.xLeftLegendOrientationText != '' ? this.chartConfig.legend.xLeftLegendOrientationText : -50,
                    xRightLegendOrientationText: this.chartConfig !== undefined && this.chartConfig.legend && this.chartConfig.legend.xRightLegendOrientationText && this.chartConfig.legend.xRightLegendOrientationText != '' ? this.chartConfig.legend.xRightLegendOrientationText : -29,
                    xLeftLegendOrientationLine: this.chartConfig !== undefined && this.chartConfig.legend && this.chartConfig.legend.xLeftLegendOrientationLine && this.chartConfig.legend.xLeftLegendOrientationLine != '' ? this.chartConfig.legend.xLeftLegendOrientationLine : -45,
                    xRightLegendOrientationLine: this.chartConfig !== undefined && this.chartConfig.legend && this.chartConfig.legend.xRightLegendOrientationLine && this.chartConfig.legend.xRightLegendOrientationLine != '' ? this.chartConfig.legend.xRightLegendOrientationLine : -11,
                    yTopLegendOrientationText: this.chartConfig !== undefined && this.chartConfig.legend && this.chartConfig.legend.yTopLegendOrientationText && this.chartConfig.legend.yTopLegendOrientationText != '' ? this.chartConfig.legend.yTopLegendOrientationText : -25,
                    yLeftRightLegendOrientationText: this.chartConfig !== undefined && this.chartConfig.legend && this.chartConfig.legend.yLeftRightLegendOrientationText && this.chartConfig.legend.yLeftRightLegendOrientationText != ''? this.chartConfig.legend.yLeftRightLegendOrientationText : 20,
                    yTopLegendOrientationLine: this.chartConfig !== undefined && this.chartConfig.legend &&  this.chartConfig.legend.yTopLegendOrientationLine && this.chartConfig.legend.yTopLegendOrientationLine != '' ? this.chartConfig.legend.yTopLegendOrientationLine : -31,
                    yLeftRightLegendOrientationLine: this.chartConfig !== undefined && this.chartConfig.legend && this.chartConfig.legend.yLeftRightLegendOrientationLine && this.chartConfig.legend.yLeftRightLegendOrientationLine != '' ? this.chartConfig.legend.yLeftRightLegendOrientationLine : 20,
                    yButtonLegendOrientationLine: this.chartConfig !== undefined && this.chartConfig.legend && this.chartConfig.legend.yButtonLegendOrientationLine && this.chartConfig.legend.yButtonLegendOrientationLine != '' ? this.chartConfig.legend.yButtonLegendOrientationLine : this.height + margin.bottom - 6,
                    yButtonLegendOrientationText: this.chartConfig !== undefined && this.chartConfig.legend && this.chartConfig.legend.yButtonLegendOrientationText && this.chartConfig.legend.yButtonLegendOrientationText != '' ? this.chartConfig.legend.yButtonLegendOrientationText : this.height + margin.bottom,
                    widthLegendOrientationLine: this.chartConfig !== undefined && this.chartConfig.legend && this.chartConfig.legend.widthLegendOrientationLine && this.chartConfig.legend.widthLegendOrientationLine != '' ? this.chartConfig.legend.widthLegendOrientationLine : 12,
                    heightLegendOrientationLine: this.chartConfig !== undefined && this.chartConfig.legend && this.chartConfig.legend.heightLegendOrientationLine && this.chartConfig.legend.heightLegendOrientationLine != '' ? this.chartConfig.legend.heightLegendOrientationLine : 3,
                    opacityHiddenLegendOrientation: this.chartConfig !== undefined && this.chartConfig.legend && this.chartConfig.legend.opacityHiddenLegendOrientation && this.chartConfig.legend.opacityHiddenLegendOrientation != '' ? this.chartConfig.legend.opacityHiddenLegendOrientation : '0.3',
                    enableClickLegend: this.chartConfig !== undefined && this.chartConfig.legend &&  this.chartConfig.legend.enableClickLegend !== undefined ? this.chartConfig.legend.enableClickLegend : true,
                },
                img:{
                    width: this.chartConfig && this.chartConfig.img && this.chartConfig.img.width ? this.chartConfig.img.width : 18,
                    height: this.chartConfig && this.chartConfig.img && this.chartConfig.img.height ? this.chartConfig.img.height : 18,
                    x: this.chartConfig && this.chartConfig.img && this.chartConfig.img.x ? this.chartConfig.img.x : -8,
                    y: this.chartConfig && this.chartConfig.img && this.chartConfig.img.y ? this.chartConfig.img.y : 9,
                    transform: this.chartConfig && this.chartConfig.img && this.chartConfig.img.transform ? this.chartConfig.img.transform : '',
                },
                tooltip:{
                    dyprincipal: this.chartConfig && this.chartConfig.tooltip && this.chartConfig.tooltip.dyprincipal ? this.chartConfig.tooltip.dyprincipal : this.dataBlock.imgmode && this.dataBlock.imgmode == true ? "2.43em" : "1.4em",
                    dyitem: this.chartConfig && this.chartConfig.tooltip && this.chartConfig.tooltip.dyitem ? this.chartConfig.tooltip.dyitem : "1.43em",
                    quitstrokewidth: this.chartConfig && this.chartConfig.tooltip && this.chartConfig.tooltip.quitstrokewidth ? this.chartConfig.tooltip.quitstrokewidth : false,
                    opacitytooltip: this.chartConfig && this.chartConfig.tooltip && this.chartConfig.tooltip.opacitytooltip ? this.chartConfig.tooltip.opacitytooltip : 0.9,
                    quitopacitytooltip: this.chartConfig && this.chartConfig.tooltip && this.chartConfig.tooltip.quitopacitytooltip ? this.chartConfig.tooltip.quitopacitytooltip : false,
                    colorStroke: colorstroke,
                    widthStroke: this.chartConfig && this.chartConfig.tooltip && this.chartConfig.tooltip.widthStroke ? this.chartConfig.tooltip.widthStroke : 2,
                    colorBackground: colorbackground,
                    widthimagetooltip: this.chartConfig && this.chartConfig.tooltip && this.chartConfig.tooltip.widthimagetooltip ? this.chartConfig.tooltip.widthimagetooltip : 15,
                    heightimagetooltip: this.chartConfig && this.chartConfig.tooltip && this.chartConfig.tooltip.heightimagetooltip ? this.chartConfig.tooltip.heightimagetooltip : 15,
                    onlydatalegend: this.chartConfig !== undefined && this.chartConfig.tooltip && this.chartConfig.tooltip.onlydatalegend ? this.chartConfig.tooltip.onlydatalegend : false,
                    rectstyles: this.chartConfig && this.chartConfig.tooltip && this.chartConfig.tooltip.rectstyles ? this.chartConfig.tooltip.rectstyles : '',
                    rectclass: this.chartConfig && this.chartConfig.tooltip && this.chartConfig.tooltip.rectclass ? this.chartConfig.tooltip.rectclass : '',
                    stylestitlex: this.chartConfig && this.chartConfig.tooltip && this.chartConfig.tooltip.stylestitlex ? this.chartConfig.tooltip.stylestitlex : '',
                    classtitlex: this.chartConfig && this.chartConfig.tooltip && this.chartConfig.tooltip.classtitlex ? this.chartConfig.tooltip.classtitlex : '',
                    quittooltip: this.chartConfig && this.chartConfig.tooltip && this.chartConfig.tooltip.quittooltip ? this.chartConfig.tooltip.quittooltip : false,
                    colortextx: this.chartConfig && this.chartConfig.tooltip && this.chartConfig.tooltip.colortextx ? this.chartConfig.tooltip.colortextx : 'black',
                    margin:{
                        xtextlabelorientation: xtextlabelorientation,
                        xtextlabel: xtextlabelorientation == 'left' ? this.chartConfig && this.chartConfig.tooltip && this.chartConfig.tooltip.margin && this.chartConfig.tooltip.margin.xtextlabel ? this.chartConfig.tooltip.margin.xtextlabel : 10 : 0,
                        xtextlegend: this.chartConfig && this.chartConfig.tooltip && this.chartConfig.tooltip.margin && this.chartConfig.tooltip.margin.xtextlegend ? this.chartConfig.tooltip.margin.xtextlegend : 10,
                        width: this.chartConfig && this.chartConfig.tooltip && this.chartConfig.tooltip.margin && this.chartConfig.tooltip.margin.width ? this.chartConfig.tooltip.margin.width : 30,
                        height: this.chartConfig && this.chartConfig.tooltip && this.chartConfig.tooltip.margin && this.chartConfig.tooltip.margin.height ? this.chartConfig.tooltip.margin.height : 20
                    }
                },
                linepath:{
                    color: this.chartConfig && this.chartConfig.linepath && this.chartConfig.linepath.color ? this.chartConfig.linepath.color : '#eee',
                },
                bar:{
                    paddingInner: paddingInner,
                    paddingbar: this.chartConfig && this.chartConfig.bar && this.chartConfig.bar.paddingbar ? this.chartConfig.bar.paddingbar : 0.25,
                    fillopacity: this.chartConfig && this.chartConfig.bar && this.chartConfig.bar.fillopacity ? this.chartConfig.bar.fillopacity : 0.5,
                    strokewidth: this.chartConfig && this.chartConfig.bar && this.chartConfig.bar.strokewidth ? this.chartConfig.bar.strokewidth : 2,
                    fillOpacityanimation: this.chartConfig && this.chartConfig.bar && this.chartConfig.bar.fillOpacityanimation ? this.chartConfig.bar.fillOpacityanimation : 0.7,
                    strokeOpacityanimation: this.chartConfig && this.chartConfig.bar && this.chartConfig.bar.strokeOpacityanimation ? this.chartConfig.bar.strokeOpacityanimation : 3,
                },
                ytext:{
                    ytextclass: this.chartConfig && this.chartConfig.chardata && this.chartConfig.chardata.ydata && this.chartConfig.chardata.ydata.class ? this.chartConfig.chardata.ydata.class : "",
                    ytextstyle: this.chartConfig && this.chartConfig.chardata && this.chartConfig.chardata.ydata && this.chartConfig.chardata.ydata.style ? this.chartConfig.chardata.ydata.style : ""
                },
                xtext:{
                    dx: this.chartConfig && this.chartConfig.chardata && this.chartConfig.chardata.xdata && this.chartConfig.chardata.xdata.dx ? this.chartConfig.chardata.xdata.dx : "",
                    dy: this.chartConfig && this.chartConfig.chardata && this.chartConfig.chardata.xdata && this.chartConfig.chardata.xdata.dy ? this.chartConfig.chardata.xdata.dy : "",
                    y: this.chartConfig && this.chartConfig.chardata && this.chartConfig.chardata.xdata && this.chartConfig.chardata.xdata.y ? this.chartConfig.chardata.xdata.y : 10,
                    x: this.chartConfig && this.chartConfig.chardata && this.chartConfig.chardata.xdata && this.chartConfig.chardata.xdata.x ? this.chartConfig.chardata.xdata.x : null,
                    transform: this.chartConfig && this.chartConfig.chardata && this.chartConfig.chardata.xdata && this.chartConfig.chardata.xdata.transform ? this.chartConfig.chardata.xdata.transform : "",
                    xtextclass: this.chartConfig && this.chartConfig.chardata && this.chartConfig.chardata.xdata && this.chartConfig.chardata.xdata.class ? this.chartConfig.chardata.xdata.class : "",
                    xtextstyle: this.chartConfig && this.chartConfig.chardata && this.chartConfig.chardata.xdata && this.chartConfig.chardata.xdata.style ? this.chartConfig.chardata.xdata.style : "",
                },
                hidden:{
                    legendhidden: this.chartConfig && this.chartConfig.hidden && this.chartConfig.hidden.legendhidden ? this.chartConfig.hidden.legendhidden : false,
                    xhidden: this.chartConfig && this.chartConfig.hidden && this.chartConfig.hidden.xhidden ? this.chartConfig.hidden.xhidden : false,
                    yhidden: this.chartConfig && this.chartConfig.hidden && this.chartConfig.hidden.yhidden ? this.chartConfig.hidden.yhidden : false,
                    minilinex: this.chartConfig && this.chartConfig.hidden && this.chartConfig.hidden.minilinex ? this.chartConfig.hidden.minilinex : false,
                    miniliney: this.chartConfig && this.chartConfig.hidden && this.chartConfig.hidden.miniliney ? this.chartConfig.hidden.miniliney : false,
                    quitopacity: this.chartConfig && this.chartConfig.hidden && this.chartConfig.hidden.quitopacity ? this.chartConfig.hidden.quitopacity : false,
                    quitstrokewidth: this.chartConfig && this.chartConfig.hidden && this.chartConfig.hidden.quitstrokewidth ? this.chartConfig.hidden.quitstrokewidth : false,
                    ypath: this.chartConfig && this.chartConfig.hidden && this.chartConfig.hidden.ypath ? this.chartConfig.hidden.ypath : false,
                    xpath: this.chartConfig && this.chartConfig.hidden && this.chartConfig.hidden.xpath ? this.chartConfig.hidden.xpath : false,
                }
            }
            this.allfalse = this.chartData.every((date) => date.show == false);
            this.verticalbar('#chart_bar_vertical_' + this.dataBlock.id, this.configure, this.Data);
        },
        verticalbar(selector,config,data) {
            var then = this;
            var svg=d3.select(selector)
                .append("svg")
                .attr("width", config.w + config.margin.left+config.margin.right - config.margin.leftSVG)
                .attr("height",config.h + config.margin.top+config.margin.bottom);

            if (config.titlemode == true) {
                svg
                    .append("text")
                    .attr("x", config.title.x)
                    .attr("y", config.title.y)
                    .attr('class', 'tooltip_svg' + config.title.class)
                    .attr("text-anchor", "middle")
                    .attr('style', 'font-size:16;' + config.title.style)
                    .text(config.title.name);
            }

            var svgG = svg.append("g").attr("transform", "translate(" + config.margin.left + ", " + config.margin.top + ")");

            svgG.append('g')
                .attr("id", this.dataBlock.id + '_axisX')
                .attr('class', 'x axis legendX')
                .attr('transform', 'translate(0,' + config.h + ')')
                .call(config.xAxis);

            if(config.imgmode == true){
                svgG.selectAll('text').remove()
                d3.select('#' + this.dataBlock.id + '_axisX')
                    .selectAll(".tick").each(function(d){
                    d3.select(this)
                        .append('image')
                        .attr('xlink:href', d)
                        .attr('width', config.img.width)
                        .attr('height', config.img.height)
                        .attr("x", config.img.x)
                        .attr("y", config.img.y)
                        .attr('transform', config.img.transform);
                });
            }

            svgG.selectAll('text')
                .style("text-anchor", "end")
                .attr("style", config.xtext.xtextstyle)
                .attr('class', 't15b legendX' + config.xtext.xtextclass)
                .attr('transform', config.xtext.transform)
                .attr("x", config.xtext.x)
                .attr("y", config.xtext.y)
                .attr("dx", config.xtext.dx)
                .attr("dy", config.xtext.dy);

            if(this.allfalse !== true) {
                svgG.append('g')
                    .attr("id", this.dataBlock.id + 'axisY')
                    .attr('class', 'y axis legendY')
                    .style('opacity', '0')
                    .call(config.yAxis.tickFormat(function(d){
                        return then.changepercent == true ? d3.format(".0%")(d/100) : d3.format('~s')(d)
                    }))
                    .append('text');

                svgG.select('.y').style('opacity', '1');
                svgG.select('#' + this.dataBlock.id + 'axisY').selectAll('text').attr("style", config.ytext.ytextstyle).attr("class", config.ytext.ytextclass);
            }

            var slice = '';

            if(!then.dataBlock.multibar && then.dataBlock.multibar !== true) {
                slice = svgG.append("g").selectAll("g").attr('transform', 'translate(' + config.margin.top + ')').data(data).enter().append("g").attr('class', 'g-rect-var');
            }

            if(then.dataBlock.multibar && then.dataBlock.multibar == true) {
                slice = svgG.selectAll('.slice')
                    .data(data)
                    .enter().append('g')
                    .attr('class','g-rect-var')
                    .attr('transform',function (d){
                        return 'translate(' + config.x0(d.name) + ',0)';
                    })
                    .attr('pos', function (d, i){
                        return i;
                    });
            }

            if(!then.dataBlock.multibar && then.dataBlock.multibar!==true) {
                slice
                    .attr("id", function (d, i) {
                        return 'rect-vertical-' + then.dataBlock.id + '-' + i
                    })
                    .style('fill', function (d) {
                        return config.color(d.key)
                    })
                    .style('stroke', function (d) {
                        return config.color(d.key)
                    })
                    .style("fill-opacity", config.hidden.quitopacity == true ? 'none' : config.bar.fillopacity)
                    .style('stroke-width', config.hidden.quitstrokewidth !== true ? config.bar.strokewidth + 'px' : 'none')
                    .selectAll("rect")
                    .data(function (d) {
                        return d;
                    })
                    .enter()
                    .append("rect")
                    .attr("class", 'rect-vertical-event')
                    .attr("x",function (d) {
                        return config.x(d.data.name)
                    })
                    .attr("y", function (d, i) {
                        return config.y(then.changepercent == true ? d[1] / config.maxedarray[i] * 100 : d[1]);
                    })
                    .attr("height",function (d, i) {
                        return config.y(then.changepercent == true ? d[0] / config.maxedarray[i] * 100 : d[0]) - config.y(then.changepercent == true ? d[1] / config.maxedarray[i] * 100 : d[1]);
                    })
                    .attr("width", config.x.bandwidth());
            }

            if(then.dataBlock.multibar && then.dataBlock.multibar == true) {
                slice
                    .selectAll('rect')
                    .data(function (d) {
                        return d.values;
                    })
                    .enter().append('rect')
                    .attr('width', config.x1.bandwidth())
                    .attr('x', function (d) {
                        return config.x1(d.label);
                    })
                    .style('fill', function (d) {
                        return config.color(d.label)
                    })
                    .style('stroke', function (d) {
                        return config.color(d.label)
                    })
                    .style("fill-opacity", config.hidden.quitopacity == true ? 'none' : config.bar.fillopacity)
                    .style('stroke-width', config.hidden.quitstrokewidth !== true ? config.bar.strokewidth + 'px' : 'none')
                    .attr('y', function (d, i, a) {
                        return config.y(then.changepercent == true ? d.value / config.maxedarray[a[0].parentNode.attributes.pos.value] * 100 : d.value);
                    })
                    .attr('height', function (d, i, a) {
                        return config.h - config.y(then.changepercent == true ? d.value / config.maxedarray[a[0].parentNode.attributes.pos.value] * 100 : d.value)
                    })
                    .attr("class", function (d, i) {
                        return 'rect-vertical-event rect-vertical-' + then.dataBlock.id + '-' + i
                    });
            }

            slice.selectAll('.rect-vertical-event').on('mouseover', function (d,i) {
                d3.select(selector).selectAll(".rect-vertical-event").transition().duration(500).style("fill-opacity", 0.1).style("stroke-opacity", 0.1);
                d3.select(this).transition().duration(500).style("fill-opacity", config.bar.fillOpacityanimation).style("stroke-opacity", config.bar.strokeOpacityanimation);

                if(config.tooltip.quittooltip !== true) {
                    var selectedicontooltip = '';
                    tooltip.style("display", null);

                    if (config.imgmode == true) {
                        tooltipimg
                            .attr('id', 'img_icon_tooltip_' + then.dataBlock.id)
                            .attr('xlink:href', !then.dataBlock.multibar && then.dataBlock.multibar !== true ? i.data.name : i.name)
                            .attr('width', config.tooltip.widthimagetooltip)
                            .attr('height', config.tooltip.heightimagetooltip);
                    } else {
                        tooltiptext
                            .append("tspan")
                            .attr('id', 'title_icon_tooltip_' + then.dataBlock.id)
                            .attr("class", "tooltip-text-line " + config.tooltip.classtitlex)
                            .attr("x", config.tooltip.margin.xtextlabel)
                            .attr("dy", config.tooltip.dyprincipal)
                            .attr("font-size", "15px")
                            .attr("font-weight", "bold")
                            .attr('style', config.tooltip.stylestitlex)
                            .attr("fill", config.tooltip.colortextx)
                            .text(!then.dataBlock.multibar && then.dataBlock.multibar !== true ? i.data.name : i.name);
                    }

                    if (!then.dataBlock.multibar && then.dataBlock.multibar !== true) {
                        if (config.tooltip.onlydatalegend == false) {
                            then.chartData.forEach((date,index) => {
                                if (date.show == true) {
                                    tooltiptext
                                        .append("tspan")
                                        .attr("class", "tooltip-text-line tooltip_svg")
                                        .attr("x", config.tooltip.margin.xtextlegend)
                                        .attr("dy", config.imgmode == true && index === 0 ? config.tooltip.dyprincipal : config.tooltip.dyitem)
                                        .attr("font-size", "15px")
                                        .attr("font-weight", "bold")
                                        .style("fill", config.color(date.name))
                                        .text(`${date.name}: ${then.changepercent==true ? (i.data[date.name] / d3.max(config.maxedarray,d=>d)) * 100 : i.data[date.name]}`);

                                    var tooltipWidth = tooltiptext.node().getBBox().width;
                                    var tooltipHeight = tooltiptext.node().getBBox().height;

                                    tooltiprect.attr("width", tooltipWidth + config.tooltip.margin.width).attr("height", tooltipHeight + config.tooltip.margin.height);
                                    selectedicontooltip = config.imgmode == true ? d3.select('#img_icon_tooltip_' + then.dataBlock.id) : d3.select('#title_icon_tooltip_' + then.dataBlock.id);
                                    selectedicontooltip.attr('x', config.tooltip.margin.xtextlabelorientation == 'mid' ? tooltipWidth / 2 : config.tooltip.margin.xtextlabel);
                                }
                            });
                        } else {
                            var subgroupName = d3.select(this.parentNode).datum().key;
                            tooltiptext
                                .append("tspan")
                                .attr("class", "tooltip-text-line")
                                .attr("x", config.tooltip.margin.xtextlegend)
                                .attr("dy", config.tooltip.dyitem)
                                .attr("font-size", "15px")
                                .attr("font-weight", "bold")
                                .style("fill", config.color(subgroupName))
                                .text(`${subgroupName}: ${i[1]}`);

                            var tooltipWidth = tooltiptext.node().getBBox().width;
                            var tooltipHeight = tooltiptext.node().getBBox().height;

                            tooltiprect.attr("width", tooltipWidth + config.tooltip.margin.width).attr("height", tooltipHeight+config.tooltip.margin.height);
                            selectedicontooltip = config.imgmode == true ? d3.select('#img_icon_tooltip_' + then.dataBlock.id) : d3.select('#title_icon_tooltip_' + then.dataBlock.id);
                            selectedicontooltip.attr('x', config.tooltip.margin.xtextlabelorientation == 'mid' ? tooltipWidth / 2 : config.tooltip.margin.xtextlabel);
                        }
                    } else {
                        if (config.tooltip.onlydatalegend == false) {
                            data.forEach((date) => {
                                if (i.name == date.name) {
                                    date.values.forEach((val,index) => {
                                        tooltiptext
                                            .append("tspan")
                                            .attr("class", "tooltip-text-line")
                                            .attr("x", config.tooltip.margin.xtextlegend)
                                            .attr("dy", config.imgmode == true && index===0 ? config.tooltip.dyprincipal : config.tooltip.dyitem)
                                            .attr("font-size", "15px")
                                            .attr("font-weight", "bold")
                                            .style("fill", config.color(val.label))
                                            .text(`${val.label}: ${val.value}`);

                                        var tooltipWidth = tooltiptext.node().getBBox().width;
                                        var tooltipHeight = tooltiptext.node().getBBox().height;

                                        tooltiprect.attr("width", tooltipWidth + config.tooltip.margin.width).attr("height", tooltipHeight + config.tooltip.margin.height + 56);
                                        selectedicontooltip = config.imgmode == true ? d3.select('#img_icon_tooltip_' + then.dataBlock.id) : d3.select('#title_icon_tooltip_' + then.dataBlock.id);
                                        selectedicontooltip.attr('x', config.tooltip.margin.xtextlabelorientation == 'mid' ? tooltipWidth / 2 : config.tooltip.margin.xtextlabel);
                                    });
                                }
                            });
                        } else {
                            tooltiptext
                                .append("tspan")
                                .attr("class","tooltip-text-line")
                                .attr("x", config.tooltip.margin.xtextlegend)
                                .attr("dy", config.tooltip.dyitem)
                                .attr("font-size", "15px")
                                .attr("font-weight", "bold")
                                .style("fill", config.color(i.label))
                                .text(`${i.label}: ${i.value}`);

                            tooltipWidth = tooltiptext.node().getBBox().width;
                            tooltipHeight = tooltiptext.node().getBBox().height;

                            tooltiprect.attr("width", tooltipWidth + config.tooltip.margin.width).attr("height", tooltipHeight + config.tooltip.margin.height);
                            selectedicontooltip = config.imgmode == true ? d3.select('#img_icon_tooltip_' + then.dataBlock.id) : d3.select('#title_icon_tooltip_' + then.dataBlock.id);
                            selectedicontooltip.attr('x', config.tooltip.margin.xtextlabelorientation == 'mid' ? tooltipWidth / 2 : config.tooltip.margin.xtextlabel);
                        }
                    }
                    config.imgmode == true ? d3.select('img_icon_tooltip_' + then.dataBlock.id).attr('x', tooltiprect.selectAll('.rect-tooltip')._parents[0].width.animVal.value / 2) : '';
                }
                tooltip.raise();
            }).on("mousemove", function(d,i) {
                if(config.tooltip.quittooltip !== true) {
                    var num = then.dataBlock.multibar && then.dataBlock.multibar == true ? config.x0(i.name) : 0;
                    var limitheight = config.h + config.margin.top + config.margin.bottom;
                    var limitwidth = config.w + config.margin.left + config.margin.right;
                    var limitW = d3.pointer(d)[0] + num + tooltiprect.selectAll('.rect-tooltip')._parents[0].width.animVal.value+config.margin.right;
                    var limitY = d3.pointer(d)[1] + tooltiprect.selectAll('.rect-tooltip')._parents[0].height.animVal.value+config.margin.bottom;
                    var xPosition = limitwidth > limitW ? d3.pointer(d)[0] + num + 15 : (d3.pointer(d)[0] + num) - (tooltiprect.selectAll('.rect-tooltip')._parents[0].width.animVal.value + 15);
                    var yPosition = limitheight>limitY ? d3.pointer(d)[1] - 15 : d3.pointer(d)[1] - (tooltiprect.selectAll('.rect-tooltip')._parents[0].height.animVal.value);
                    tooltip.attr("transform", "translate(" + xPosition + "," + yPosition + ")");
                }
            }).on('mouseout', function () {
                config.tooltip.quittooltip !== true ? tooltip.style("display", "none") : '';
                config.tooltip.quittooltip !== true ? tooltiptext.selectAll(".tooltip-text-line").remove() : '';
                d3.select(selector).selectAll(".rect-vertical-event").transition().duration(500).style("fill-opacity", config.hidden.quitopacity == true ? null : config.bar.fillopacity).style("stroke-opacity", null);
            });

            d3.select('#' + this.dataBlock.id + 'axisY').selectAll("line").style('fill', 'none').style('stroke','#eee').style("width",100).style('display', function(d, i) {
                if (i == 0){
                    return 'none';
                }
            }).style("shape-rendering",'crispEdges');

            if(config.tooltip.quittooltip!==true) {
                var tooltip = svgG.append("g").attr("class", "tooltip").style("display", "none");
                var tooltiprect = tooltip.append("rect").attr('class', 'rect-tooltip');
                config.tooltip.quitstrokewidth !== true ? tooltiprect.attr('stroke-width', config.tooltip.widthStroke + 'px').attr('stroke', config.tooltip.colorStroke) : '';
                var tooltiptext = tooltip.append("text");
                var tooltipimg = tooltip.append("image");
                tooltiprect.attr("fill", config.tooltip.colorBackground).attr('class', config.tooltip.rectclass).attr('style', config.tooltip.rectstyles).style("opacity", config.tooltip.quitopacitytooltip !== true ? config.tooltip.opacitytooltip : null);
            }

            if(config.hidden.yhidden == true){
                d3.select('#' + this.dataBlock.id + 'axisY').selectAll("text").style('display', 'none');
            }

            config.yAxis = g => g.attr("transform", `translate(200,0)`);
            config.hidden.miniliney == true ? d3.select('#' + this.dataBlock.id + 'axisY').selectAll("line").style('visibility', 'hidden') : '';
            config.hidden.minilinex == true ? d3.select('#' + this.dataBlock.id + '_axisX').selectAll("line").style('visibility', 'hidden') : '';
            config.hidden.xpath == true ? d3.select('#' + this.dataBlock.id + '_axisX').selectAll("path").remove() : '';
            config.hidden.ypath == true ? d3.select('#' + this.dataBlock.id + 'axisY').selectAll("path").remove() : '';
            config.hidden.xhidden == true ? config.imgmode !== true ? d3.select('#' + this.dataBlock.id + '_axisX').selectAll("text").remove() : d3.select('#' + this.dataBlock.id + '_axisX').selectAll("image").remove() : '';
            config.hidden.yhidden == true ? d3.select('#' + this.dataBlock.id + 'axisY').selectAll("text").remove() : '';

            if(config.hidden.miniliney !== true) {
                d3.select('#' + this.dataBlock.id + 'axisY').selectAll("line").style('fill', 'none').style('stroke', config.linepath.color).style('visibility', function (d, i) {
                    if (i == 0){
                        return 'hidden';
                    }
                }).style("shape-rendering", 'crispEdges');
            }else {
                d3.select('#' + this.dataBlock.id + 'axisY').selectAll("line").style('visibility', 'hidden');
            }

            if(config.hidden.legendhidden == false) {
                var legendSpace = config.w / this.chartData.length;
                var legend = svgG.append('g').attr('class', 'legendGroup');
                var datablockId = this.dataBlock.id;

                this.chartData.forEach((d, i) => {
                    var legendGroup = legend.append('g')
                        .attr('class', 'legend')
                        .attr('id', 'legend_' + datablockId + i)
                        .style('opacity', d.show ? '' : config.legend.opacityHiddenLegendOrientation);

                    legendGroup.selectAll(".lineLegend")
                        .append('g')
                        .attr('class', 'lineLegend')
                        .attr('transform', function (d, i) {
                            return 'translate(' + config.legend.orientation == 'left' ? config.legend.xLeftLegendOrientationText : config.legend.orientation == 'right' ? config.w - config.legend.xRightLegendOrientationText : (legendSpace / 2) + i * legendSpace + ',' + config.legend.orientation == 'top' ? config.legend.yTopLegendOrientationText : config.legend.orientation== 'left' ? i * config.legend.yLeftRightLegendOrientationText : config.h + ')';
                        });


                    legendGroup.append("text")
                        .attr("x", config.legend.orientation == 'left' ? config.legend.xLeftLegendOrientationText : config.legend.orientation == 'right' ? config.w - config.legend.xRightLegendOrientationText : ((legendSpace / 2) + i * legendSpace) - 40)
                        .attr("y", config.legend.orientation == 'top' ? config.legend.yTopLegendOrientationText : config.legend.orientation == 'left' || config.legend.orientation == 'right' ? (i + 0.3) * config.legend.yLeftRightLegendOrientationText : config.legend.yButtonLegendOrientationText - 5)
                        .attr("class", "legend link " + config.legend.classtext)
                        .attr('text-anchor', config.legend.orientation == 'left' ? 'end' : '')
                        .style('cursor', 'pointer')
                        .attr('style', config.legend.styletext)
                        .style("fill", config.color(d.name))
                        .text(d.name);

                    legendGroup.append("rect")
                        .attr("fill", config.color(d.name))
                        .attr("class", "link " + config.legend.classrect)
                        .attr('style', config.legend.stylerect)
                        .attr('x', config.legend.orientation == 'left' ? config.legend.xLeftLegendOrientationLine : config.legend.orientation == 'right' ? config.w - config.legend.xRightLegendOrientationLine : ((legendSpace / 2) + i * legendSpace) - 60)
                        .attr('y', config.legend.orientation == 'top' ? config.legend.yTopLegendOrientationLine : config.legend.orientation == 'left' || config.legend.orientation == 'right' ? i * config.legend.yLeftRightLegendOrientationLine : config.legend.yButtonLegendOrientationLine - 5)
                        .attr("width", config.legend.widthLegendOrientationLine)
                        .attr('height', config.legend.heightLegendOrientationLine);

                    var then = this;

                    if(config.legend.enableClickLegend) {
                        legendGroup.on("click", function () {
                            d.show == true ? d.show = false : d.show = true;
                            then.allfalse = then.chartData.every((date) => date.show == false);

                            if (!then.dataBlock.multibar && then.dataBlock.multibar !== true) {
                                d.show == false ? svgG.select('#rect-vertical-' + datablockId + '-' + i).attr("visibility", "hidden") : svgG.select('#rect-vertical-' + datablockId + '-' + i).attr("visibility", null);

                                var keyslegend = new Array();
                                var datenew = new Array();
                                var maxedarray = new Array();

                                config.chartdata.forEach((e) => {e.show == true ? keyslegend.push(e.name) : ''});
                                config.extravalues.values.forEach((e) => {datenew.push({name: e.label})});

                                config.extravalues.values.forEach((data) => {
                                    var label = data.label;
                                    var acum = new Array();
                                    var suma = 0;
                                    then.chartData.forEach((subdata) => {
                                        if (subdata.show == true) {
                                            subdata.values.forEach((val) => {
                                                if (val.label == label) {
                                                    acum.push(val.value);
                                                }
                                            });
                                        }
                                    });

                                    suma = acum.reduce((prev, after) => prev + after, 0);
                                    maxedarray.push(suma);
                                });

                                config.maxedarray = maxedarray;

                                datenew.forEach((d) => {
                                    config.chartdata.forEach((e,i) => {
                                        if(e.show == true){
                                            d[e.name] = e.values[config.chartdata[i].values.findIndex((data) => data.label == d.name)].value
                                        }
                                    });
                                });

                                var stack = d3.stack().keys(keyslegend);
                                then.maxValue = then.chartConfig && then.chartConfig.maxRange ? then.changepercent== true ? 100 : then.chartConfig.maxRange : d3.max(maxedarray, d => then.chartConfig && then.chartConfig.percentage == true ? 100 : d);
                                config.y = d3.scaleLinear().range([config.h, 0]).domain([0, then.maxValue]);
                                config.yAxis = d3.axisLeft().scale(config.y).ticks(config.ticksy).tickSizeInner(-config.w).tickPadding(config.tickPadding);
                                config.yAxis.tickFormat(function(d){
                                    return then.changepercent == true ? d3.format(".0%")(d/100) : d3.format('~s')(d)
                                });
                                d3.select(this).style('opacity', d.show == false ? config.legend.opacityHiddenLegendOrientation : '');
                                then.Data = stack(datenew);
                                then.configure = config;
                            }

                            if (then.dataBlock.multibar && then.dataBlock.multibar == true) {
                                d.show == false ? svgG.selectAll('.rect-vertical-' + datablockId + '-' + i).attr("display", "none") : svgG.selectAll('.rect-vertical-' + datablockId + '-' + i).attr("display", null);
                                data.forEach((date) => {
                                    date.values.forEach((val,a) => {
                                        if (d.show == false && d.name == val.label) {
                                            date.values.splice(a, 1);
                                        }
                                    });
                                });

                                if (d.show == true) {
                                    var auxarray = '';

                                    config.chartdata.forEach((date) => {
                                        if (d.name == date.name) {
                                            auxarray = date.values;
                                        }
                                    });

                                    auxarray.forEach((date) => {
                                        data.forEach((e)=> {
                                            if (e.name == date.label) {
                                                e.values.push({
                                                    'name': date.label,
                                                    'label': d.name,
                                                    'value': date.value,
                                                });
                                            }
                                        })
                                    });

                                    data.forEach((e) => {
                                        var order = e.values;
                                        e.values = new Array();
                                        for (let i = 0; i <= config.listordername.length - 1; i++) {
                                            if (order.findIndex((data) => data.label == config.listordername[i].name) !== -1) {
                                                e.values.push({
                                                    'name': order[order.findIndex((data) => data.label == config.listordername[i].name)].name,
                                                    'label': order[order.findIndex((data) => data.label == config.listordername[i].name)].label,
                                                    'value': order[order.findIndex((data) => data.label == config.listordername[i].name)].value,
                                                });
                                            }
                                        }
                                    });
                                }

                                maxedarray = new Array();

                                data.forEach((subdata)=>{
                                    var yearSum = '';
                                    if(then.dataBlock.showMax){
                                        yearSum = d3.max(subdata.values, function (d) {
                                            return d.value;
                                        });
                                    }else {
                                        yearSum = d3.sum(subdata.values, function (d) {
                                            return d.value;
                                        });
                                    }
                                    maxedarray.push(yearSum);
                                });

                                config.maxedarray = maxedarray;

                                config.x0 = d3.scaleBand().rangeRound([0,config.w], .5).paddingInner(config.bar.paddingInner).domain(data.map(function (d) {
                                    return d.name;
                                }));

                                config.x1 = d3.scaleBand().padding(config.bar.paddingbar).domain(data[0].values.map(function (e) {
                                    return e.label;
                                })).rangeRound([0, config.x0.bandwidth()]);

                                then.maxValue = then.chartConfig && then.chartConfig.maxRange ? then.changepercent == true ? 100 : then.chartConfig.maxRange:d3.max(maxedarray, d => then.changepercent == true ? 100 : d);
                                config.y = d3.scaleLinear().range([config.h,0]).domain([0,then.maxValue]);
                                config.xAxis = d3.axisBottom().scale(config.x0).tickValues(data.map(d => d.name));
                                config.yAxis = d3.axisLeft().scale(config.y).ticks(config.ticksy).tickSizeInner(-config.w).tickPadding(config.tickPadding);
                                d3.select(this).style('opacity', d.show == false ? config.legend.opacityHiddenLegendOrientation : '');
                                this.Data = data;
                                this.configure = config;
                            }

                            document.getElementById(selector.split('#')[1]).innerHTML = '';
                            then.verticalbar(selector, then.configure, then.Data);
                        });
                    }
                });
            }
            this.chartData.forEach((data,i) => {
                if (!then.dataBlock.multibar && then.dataBlock.multibar !== true) {
                    data.show == false ? d3.select('#rect-vertical-' + this.dataBlock.id + '-' + i).attr("visibility", "hidden") : '';
                    data.show == false ? document.getElementById('legend_' + datablockId + i).style.opacity = config.legend.opacityHiddenLegendOrientation : '';
                }else{
                    document.getElementById('legend_' + datablockId + i).style.opacity = data.show ? '' : config.legend.opacityHiddenLegendOrientation;
                }
            });
        }
    }
}
</script>