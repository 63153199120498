import { render, staticRenderFns } from "./GhSearchWindow.vue?vue&type=template&id=63d193d2"
import script from "./GhSearchWindow.vue?vue&type=script&lang=js"
export * from "./GhSearchWindow.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports