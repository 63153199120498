<template>
    <div :id="dataBlock.id + 'environment_box'" class="environment_box">
        <div @mouseleave="linkhovervisible(false)" @mouseenter="linkhovervisible(true)">
            <div class="environment_box-title F3 C1">
                {{$t(dataBlock.title)}}
            </div>
            <div class="environment_box-image">
                <router-link :to="dataBlock.params ? {name: dataBlock.nameview, params: dataBlock.params}: {name: dataBlock.nameview} " >
                    <img :id="'environment-img-' + dataBlock.id"  :src="showhover == true ? environment.img_hover: environment.img_visible"/>
                </router-link>
            </div>
        </div>
        <div :id="'environment-subtitle_'+dataBlock.id" class="environment_box-info  F2 C1" v-show="showhover">
            {{$t(dataBlock.subtitle)}}
        </div>
    </div>
</template>
<script>
    export default {
        name: "GhEnvironment",
        props:['dataBlock','environment'],
        data(){
            return{
                showhover:false,
            }
        },
        methods:{
            linkhovervisible(bolean){
                  this.showhover = bolean;
                  let backgroundcolor = this.dataBlock.backgroundColor ? this.dataBlock.backgroundColor:'#FFF8E8'
                  document.getElementById(this.dataBlock.id + 'environment_box').style.backgroundColor = this.showhover == true ? backgroundcolor : '#FFFFFF';
                  if(this.showhover == true){
                      document.getElementById('environment-subtitle_' + this.dataBlock.id).style.backgroundColor = backgroundcolor;
                  }else{
                      document.getElementById('environment-subtitle_' + this.dataBlock.id).style.removeProperty('background-color')
                  }
            },
        },
    }
</script>
